/**!
 * 	SHARING-IS-CARING
 *
 *  @name			   sharing-is-caring.js
 *  @desc			   module w/ facebook-api features.
 *
 *  @client			 WEIGELSTEIN
 *  @author 		 Ansgar Hiller <ansgar@weigelstein.de>
 *  @since			 06-2023
*/

import $ from 'jquery';
import * as FB from 'fb-sdk-wrapper';

const TW_ENDPOINT = 'https://twitter.com/share';

class SharingIsCaring
{
		constructor(appId, debug = false)
		{
        if (debug) console.log(`SharingIsCaring::constructor (js/ws/share/sharing-is-caring.js)`);
        this._debug = debug;
				this.status = null;

				FB.load().then(
            () => FB.init({appId:appId})).then(
            () => {
                if (debug) console.log(`SharingIsCaring::constructor -> FB.load -> FB.init`);
                this._getStatus()
            }
        );
		}

		wireChildren(parent)
		{
        if (this._debug) console.log(`SharingIsCaring::wireChildren`);

				let
				_this = this,
				_fb = parent.find('.fb-button'),
        _tw = parent.find('.tw-button');

				if (_fb.length) {
            _fb.on({ 'click': _this._fbShare.bind(this) });
        }
        if (_tw.length) {
            _tw.on({ 'click': _this._twShare.bind(this) });
        }
		}

		_getStatus()
		{
        if (this._debug) console.log(`SharingIsCaring::_getStatus`);

				FB.getLoginStatus()
						.then((response) => {
								if (response.status === 'connected') {
										this.status = 'logged in';
								} else {
										this.status = 'not logged in';
								}
                if (this._debug) console.log(`SharingIsCaring::_getStatus w/ status: ${this.status}`);
						}
        );
		}

		_fbShare(e)
		{
        if (this._debug) console.log(`SharingIsCaring::_fbShare Event`);

				let data = $(e.delegateTarget).data();

        if (this._debug) console.log(data);

				FB.ui({
						display: 'popup',
						 method: 'share',
						   href: data.url
				}, (response) => {
            console.log(response);
				});
		}

    _twShare(e)
		{
				let
        data = $(e.target).data(),
        width  = 575,
  		  height = 400,
  		  left   = ($(window).width()  - width)  / 2,
  		  top    = ($(window).height() - height) / 2,
  		  url    = TW_ENDPOINT + '?url=' + data.url + '&text=' + data.text,
  		  opts   =
  		      'status=1' +
  		      ',width='  + width  +
  		      ',height=' + height +
  		      ',top='    + top    +
  		      ',left='   + left;

  		  window.open(url, 'twitter', opts);
		}
}

export default SharingIsCaring;

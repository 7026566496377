/**!
 *  VIMEO-PLAYER
 *
 *	! -- WIP -- !
 *
 *  @name			   vimeo.js
 *  @desc			   vimeo video player.
 *
 *  @client			 WEIGELSTEIN
 *  @author 		 Ansgar Hiller <ansgar@weigelstein.de>
 *  @since			 01-2021
*/

import $ from 'jquery';
import { gsap } from 'gsap';
import Vimeo from '@vimeo/player';

const
VIDEO_WIDTH_DEFAULT = 1200;

class VimeoPlayer
{
		get width() 			{ return this._opt.width; }

    set DEBUG(debug) 	{ this._debug = debug; }
		get DEBUG() 			{ return this._debug; }

		constructor(element)
		{
				this._element 	= element;
				this._id 				= this._element.attr('id');
        this._debug 		= DEBUG;
				this._opt = {
						width: VIDEO_WIDTH_DEFAULT
				}
				$.extend(this._opt, this._element.data());

				if (this.DEBUG) console.log(`VimeoPlayer::constructor (js/ws/vimeo.js) w/ id = ${this._id}`);

				this._player = new Vimeo(this._id, this._opt);
		}
}

export default VimeoPlayer;

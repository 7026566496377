/**!
 *  YOUTUBE-PLAYER
 *
 *  @name			   youtube.js
 *  @desc			   youtube video player.
 *	@see 			 	 https://www.jsdelivr.com/package/npm/youtube-player
 *
 *  @client			 WEIGELSTEIN
 *  @author 		 Ansgar Hiller <ansgar@weigelstein.de>
 *  @since			 08-2023
*/

import $ from 'jquery';
import { gsap } from 'gsap';

/**
 * @typedef options
 * @see https://developers.google.com/youtube/iframe_api_reference#Loading_a_Video_Player
 * @param {Number} width
 * @param {Number} height
 * @param {String} videoId
 * @param {Object} playerVars
 * @param {Object} events
 */

/**
 * @typedef YT.Player
 * @see https://developers.google.com/youtube/iframe_api_reference
 * */

/**
 * A factory function used to produce an instance of YT.Player and queue function calls and proxy events of the resulting object.
 *
 * @param {YT.Player|HTMLElement|String} elementId Either An existing YT.Player instance,
 * the DOM element or the id of the HTML element where the API will insert an <iframe>.
 * @param {YouTubePlayer~options} options See `options` (Ignored when using an existing YT.Player instance).
 * @param {boolean} strictState A flag designating whether or not to wait for
 * an acceptable state when calling supported functions. Default: `false`.
 * See `FunctionStateMap.js` for supported functions and acceptable states.
 * @returns {Object}
 */
import YTPlayer from 'youtube-player';

class YouTubePlayer
{
		get DEBUG() 			{ return this._debug; }
		set DEBUG(debug)	{ this._debug = debug; }

		get state()  			{ return this._state; }
		set state(state)  { this._state = state; }

		get video() 			{ return this._player; }
		get player() 			{ return this._player; }
		get options() 		{ return this._opt; }

    get btnTogglePlay() { return this._btnTogglePlay; }
    get poster() { return this._poster; }

		constructor(element)
		{
				this._debug 				= DEBUG;

				this._element 			= element;
				this._player 				= this._element.find('.player');
				this._id 						= this._player.attr('id');
				this._timeoutHideId = null;
				this._state 				= 'paused';

				// ---------------
				if (this._debug) console.log(`YTPlayer::constructor (js/ws/youtube.js) w/ id = ${this._id}`);
				// ---------------

				// Options
				this._opt = {
											playerVars: { 'autoplay': 0 },
						 hidePlaybuttonDelay: 2
				};
				$.extend(this._opt, this._element.data());

				// ---------------
				if (this._debug) console.log(this.options);
				// ---------------

				// Poster-Layer (<div class="poster-layer" />)
        this._posterURL = (this._opt.poster !== null) ? this._opt.poster : false;
        this._poster = (this._element.find('.poster-layer').length) ?
            this._element.find('.poster-layer') :
						$('<div/>')
								.addClass('poster-layer fade')
								.appendTo(this._element);

        // Toggle-Play Button
        this._btnTogglePlay = (this._element.find('[name="togglePlay"]').length) ?
						this._element.find('[name="togglePlay"]') :
						$('<button/>')
								.addClass('btn btn-link toggle-play fade')
								.attr({
                    'name': 'togglePlay',
                    'type': 'button'
                })
								.appendTo(this._element);

				let
        _icnPlay  = $('<span />').addClass('icon icon-controller-play icon-play').appendTo(this._btnTogglePlay),
        _icnPause = $('<span />').addClass('icon icon-controller-paus icon-paus').appendTo(this._btnTogglePlay);

				this._btnTogglePlay.on(
            {
                'click': this.__togglePlay.bind(this),
                'mouseenter mouseleave mousemove': this.__hover.bind(this),
            }
        );

				this._player = YTPlayer(this._id, this.options);

				this._player.on('stateChange', this.__status.bind(this));
				this._player.on('ready', this.__init.bind(this));
		}

		__init()
		{
				// ---------------
				if (this._debug) console.log(`YouTubePlayer::__init`);
				// ---------------

				if (this._posterURL) this._poster.css({ 'background-image': 'url(' + this._posterURL + ')' });

				if (this.options.playerVars.autoplay)
				{
						this.player.playVideo();
				} else {
						this.player.pauseVideo();
						this.btnTogglePlay.addClass('show');
            this.poster.addClass('show');
				}
		}

		__hover(e)
		{
				// ---------------
				if (this._debug) console.log(`YouTubePlayer::__hover w/ type = ${e.type}`);
				// ---------------

        let _this = this;

        if (this.state !== 'playing') return;
				if (this._timeoutHideId) clearTimeout(this._timeoutHideId);
				switch(e.type) {
						case 'mouseenter':
            case 'mousemove':
								this.btnTogglePlay.addClass('show');
								this._hidePlayButton();
						break;
						case 'mouseleave':
								this.btnTogglePlay.removeClass('show');
						break;
				}
		}

		__togglePlay(e)
		{
				// ---------------
        if (this._debug) console.log(`YouTubePlayer::__togglePlay ${(this.state === 'paused' || this.state === 'ended') ? 'play':'pause'}`);
				// ---------------

			  if (this.state === 'paused' || this.state === 'ended')
				{
						if (this._timeoutHideId) clearTimeout(this._timeoutHideId);
						this.player.playVideo();
			  } else {
			    	this.player.pauseVideo();
			  }
		}

		__status(e)
    {
				switch(e.data)
				{
						case -1:
								this.state = 'ended';
								break;
						case 0:
								this.state = 'ended';
								break;
						case 1:
								this.state = 'playing';
								break;
						case 2:
								this.state = 'paused';
								break;
						case 3:
								this.state = 'buffering';
								break;
						case 5:
								this.state = 'cued';
								break;
				}

				// ---------------
				if (this._debug) console.log(`YouTubePlayer::__status = ${this.state} (${e.data})`);
				// ---------------

				switch(this.state)
				{
						case 'playing':
						case 'buffering':
								this.btnTogglePlay.addClass('playing');
                this.poster.removeClass('show');
								this._hidePlayButton();
								break;
						case 'paused':
            case 'ended':
								this.btnTogglePlay.removeClass('playing').addClass('show');
                this.poster.addClass('show');
								if (this._timeoutHideId) clearTimeout(this._timeoutHideId);
								break;
						case 'cued':
								// do something
								break;
				}
		}

		_hidePlayButton()
		{
				let _this = this;

				this._timeoutHideId = setTimeout(
						function() {
								_this.btnTogglePlay.removeClass('show');
						},
						_this.options.hidePlaybuttonDelay * 1000
				);
		}

		loadVideoById(id)
		{
				this.player.loadVideoById(id);
		}
}

export default YouTubePlayer;

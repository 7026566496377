/**!
 *  @name				   utils.js (js/ws/utils.js)
 *
 *  @client	       DGSDP
 *  @package       PLUGINS
 *  @description	 tba.
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 */

import $ from 'jquery';

class Utils
{
		/**
		 *	Find GET params (everything following the '?') in current browser location
		 *
		 *	@returns Mixed
		 */
		static getGETParams()
		{
				if (DEBUG) console.log('Utils::getGETParams');

				var
				query = window.location.search.substring(1);

				if (query.length)
				{
						return query;
				}

				return false;
		}
		
		/**
		 *	Strip GET params (everything following the '?') from current browser location
		 */
		static refineURL()
		{
				// get the string following the '?'
				var
				query = window.location.search.substring(1);

				// is there anything there '?'
				if(query.length)
				{
					 // are the new history methods available ?
					 if(window.history != undefined && window.history.pushState != undefined)
					 {
								// if pushstate exists, add a new state to the history, this changes the url without reloading the page
								window.history.pushState({}, document.title, window.location.pathname);
					 }
				}
		}

		/**
		 *	Remove @value from @array and return the resulting array
		 */
		static arrayRemove(array, value)
		{
        return array.filter(function(ele) {
            return ele != value;
        });
    }
}

export default Utils;

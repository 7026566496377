/**!
 * 	GMAP-UI
 *
 *  @name			   gmap-ui.js
 *  @desc			   UI-Subpackage for Gmap Module (maps.googleapis.com).
 *
 *  @client			 WEIGELSTEIN
 *  @author 		 Ansgar Hiller <ansgar@weigelstein.de>
 *  @since			 03-2022
*/

import $ from 'jquery';
import { gsap } from 'gsap';

class GmapUI
{
		/*
		*	@param {gmap|GmapBase}
		*/
		constructor(gmap, debug = false)
		{
        this._debug = debug;

				if (typeof gmap !== 'object')  return false;
				this._gmap = gmap;

        if (debug) console.log('GmapUI::constructor (js/ws/gmap/gmap-ui.js)');

				// Custom events
				this._events = $.Callbacks();

        this._origin = null;
        this._destination = null;
				this._originPlaceId = null;
        this._originPlaceDetails = null;
        this._destinationPlaceId = null;
        this._destinationPlaceDetails = null;
        this._travelMode = this.api.TravelMode.DRIVING;

				// UI-ELEMENTS
        this._centerBtn = this.element.find('[data-action="center"]').first();
        this._zoomInBtn = this.element.find('[data-action="zoom_in"]').first();
        this._zoomOutBtn = this.element.find('[data-action="zoom_out"]').first();
        this._locationSelector = this.element.find('input[name="location"]').first();
				this._directionsPanel = document.getElementById('directionsPanel');
				this._directions = $(this._directionsPanel).find('#directions');
				this._pacInput = $(this._directionsPanel).find('#gmStartAddress');
    	 	this._getUserGeolocationBtn = $(this._directionsPanel).find('[data-action="get_current_location"]').first();
				this._travelModeSelector = $(this._directionsPanel).find('input[name="travelmode"]');

        this.DirectionsDisplay = new this.api.DirectionsRenderer({ draggable: true });
        this.DirectionsDisplay.setMap(this.map);
        this.DirectionsDisplay.setPanel(this._directions[0]);

				var _this = this;

				// Pac-Input
				if (this._pacInput.length)
				{
						this._addressAutocomplete = new this.api.places.Autocomplete((this._pacInput[0]));
						this._addressAutocomplete.bindTo('bounds', this.map);
						this._addressAutocomplete.addListener('place_changed', () =>
								{
										var place = _this._addressAutocomplete.getPlace();
                    _this.origin = place.geometry.location;
								}
						);
				}

        // Get-User-Geolocation
        if (this._getUserGeolocationBtn.length)
				{
						this._getUserGeolocationBtn
								.on(
		                {
		                    'click': this._getUserGeolocation.bind(this)
		                }
		            )
						;
				}

        // Center
        if (this._centerBtn.length)
				{
						this._centerBtn.on('click', function()
						{
                _this.center = _this.defaultCenter;
                _this.zoom = _this.options.zoom;
						});
				}

        // Zoom-In
				if (this._zoomInBtn.length)
				{
						this._zoomInBtn.on('click', function()
						{
									if (_this.zoom < _this.options.zoomMax) _this.zoom++;
						});
				}

        // Zoom-Out
				if (this._zoomOutBtn.length)
				{
						this._zoomOutBtn.on('click', function()
						{
								if (_this.zoom > _this.options.zoomMin) _this.zoom--;
						});
				}

        // Travel-Mode
        if (this._travelModeSelector.length)
				{
						this._travelModeSelector
                .on(
                    {
                      'change': this._onTravelModeChange.bind(this)
                    }
						    )
            ;
				}

        // Directions-Panel
        if ($(this._directionsPanel).length)
				{
            // Directions-Panel is placed in a TWBS 'Offcanvas' element
            this._directionsPanel.addEventListener('show.bs.offcanvas', function (e)
                {
                    if (_this._debug) console.log('Directions-Panel: show');
                    if (typeof _this.smoother === 'object')
                    {
                        _this.smoother.scrollTo(_this.element);
                    }
                }
            );
            this._directionsPanel.addEventListener('shown.bs.offcanvas', function (e)
                {
                    if (_this._debug) console.log('Directions-Panel: shown');
                    if (typeof _this.smoother === 'object')
                    {

                    }
                }
            );
            this._directionsPanel.addEventListener('hide.bs.offcanvas', function (e)
                {
                    if (_this._debug) console.log('Directions-Panel: hide');
                    if (typeof _this.smoother === 'object')
                    {

                    }
                }
            );
            this._directionsPanel.addEventListener('hidden.bs.offcanvas', function (e)
                {
                    if (_this._debug) console.log('Directions-Panel: hidden');
                    if (typeof _this.smoother === 'object')
                    {

                    }

                    _this.utils.clearRoute();
                }
            );
        }
		}

		// PRIVATE METHODS

		/**!
     *  _dispatch()
     *
     *  -> Dispatch custom events
     *  @param: fn [function]
     *  @param: params [object]
     *  @param: string [string]
     */
		_dispatch(fn, params, string)
		{
        if (this._debug) console.log(`GmapUI::_dispatch (Custom-Event-Dispatcher)`);

				if ( typeof fn === 'function' ) {
						this._events
								.add(fn)
								.fire(params, string, this)
								.remove(fn);
				}
		}

    // Get User Geolocation Listener
		_getUserGeolocation()
		{
        if (this._debug) console.log(`GmapUI::_getUserGeolocation (private)`);
        var _this = this;

        this.utils.getUserGeolocation({
            onResolve: function(position, status)
            {
                _this.origin = position;
            }
        });
		}

    // TravelMode Listener
		_onTravelModeChange(e)
		{
				if (this._debug) console.log(`GMaps::_travelModeChanged (private event w/ e = ${e.type})`);
        if (this._debug) console.log($(e.target).val());

				this.travelMode = $(e.target).val();
		}

    openFullscreen() {
        if (this._debug) console.log(`GMaps::openFullscreen`);
        var elem = this.canvas;
        this.map.disableDefaultUI = false;
        if (elem.requestFullscreen)
        {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen)
        { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen)
        { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    /* Close fullscreen */
    closeFullscreen() {
        if (this._debug) console.log(`GMaps::closeFullscreen`);
        if (document.exitFullscreen)
        {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen)
        { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen)
        { /* IE11 */
            document.msExitFullscreen();
        }
    }

		// PUBLIC METHODS
    setDefaultDestinationByAddress(address, options = {})
    {
        if (this._debug) console.log(`GMaps::setDefaultDestinationByAddress w/ address = ${address}`);

        let
        _this = this,
        _opt = {
            onResolve: null,
        };
        $.extend(_opt, options);

        this.utils.Geocoder.geocode(
            {
                'address': address
            },
            function(results, status)
            {
                if (status === _this.api.GeocoderStatus.OK)
                {
                    if (results[0])
                    {
                        _this.destinationPlaceId = results[0].place_id;
                        _this.center = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        };
                        _this.destination = _this.center;

                        /* TAB IN TO LOG CENTER OF DEFAULT_ADDRESS */
                        if (_this._debug) console.log(`lat: ${results[0].geometry.location.lat()}`);
                        if (_this._debug) console.log(`lng: ${results[0].geometry.location.lng()}`);

                        _this._dispatch(_opt.onResolve, results[0], status);
                    }
                } else {
                    window.alert('Geocoder failed due to: ' + status);
                }
            }
        );
    }

    setDefaultDestinationByPosition(position)
    {
        if (this._debug) console.log(`GMaps::setDefaultDestinationByPosition w/ position = ${position}`);

        this.center = position;
        this.destination = this.center;
    }


		// READ-ONLY

		// Gmap Instance
		get g() { return this._gmap; }

		// Element
		get element() { return this.g.element; }

    // Canvas
		get canvas() { return this.g.canvas; }

    // Smoother (ScrollSmoother Instance @see: https://greensock.com/docs/v3/Plugins/ScrollSmoother)
		get smoother() { return this.g.smoother; }

    // GoogleMapsApi
		get api() { return this.g.api; }

    // GmapUtils
		get utils() { return this.g.utils; }

		// Map
		get map() { return this.g.map; }

		// Options
		get opt() { return this.g.options; }
    set opt(opt) { this.g.options = opt; }

    // Options (same as 'set opt()')
		get options() { return this.g.options; }
		set options(opt) { this.g.options = opt; }

    // Region (like f.e. 'Germany' or 'Europe, Continent')
    get region() { return this.g.region; }
    set region(region) { this.g.region = region; }

    // Template (Joomla template name)
		get template() { return this.opt.template; }

		// GETTER & SETTER

    // Inherited properties from GmapBase

    // Zoom
		get zoom() { return this.g.zoom; }
    set zoom(zoom) { this.g.zoom = zoom; }

		// (Current) Center
    get center() { return this.g.center; }
    set center(center) { this.g.center = center; }

    // Default Center
		get defaultCenter() { return this.g.defaultCenter; }
    set defaultCenter(center) { this.g.defaultCenter = center; }

    // Markers
    get markers() { return this.g.markers; }
    set markers(markers) { this.g.markers = markers; }

    // Route-Markers
    get routeMarkers() { return this.g.routeMarkers; }
    set routeMarkers(markers) { this.g.routeMarkers = markers; }

    // Native properties

    // Origin
		get origin() { return this._origin; }
    set origin(location)
    {
        if (this._debug) console.log('GmapUI set origin (user location)');
        this._origin = location;

        let _this = this;

        if (this._debug) console.log(this._origin);

        if (this._origin)
				{
						this.utils.Geocoder.geocode({'location': this._origin }, function(results, status)
						{
								if (status === _this.api.GeocoderStatus.OK)
								{
										if (results[0])
										{
												_this.originPlaceId = results[0].place_id;
												var _marker = _this.utils.createMarker({
														position: _this._origin,
														icon: _this.utils.originPin,
														descr: results[0].formatted_address,
														title: 'Start Adresse',
                            onInfoWindowClose: function(iw) {
                                _this.center = _this.g.defaultCenter;
                                _this.zoom = _this.g.options.zoom;
                                _this.g.markerInfoWindow.close();
                                _this.g.activeMarker = null;
                            }
												});
												_this.g.userLocationMarker = _marker;
												_this.g.markerInfoWindow.open(_this.map, _marker);
                        _this._pacInput.val(results[0].formatted_address);
										}
								} else {
										window.alert('Geocoder failed due to: ' + status);
								}
						});
				} else {
            // clear directions
            this.center = _this.defaultCenter;
            this.zoom = _this.options.zoom;
            this.originPlaceId = null;
            this.g.routeMarkers = [];
            this.g.userLocationMarker = null;
            this.g.markerInfoWindow.close();
            this._pacInput.val('');
            this.DirectionsDisplay.setMap(null);
            $(this.DirectionsDisplay.getPanel()).empty();
        }
    }

    // Destination
		get destination() { return this._destination; }
    set destination(location) {
        if (this._debug) console.log('GmapUI set destination');
        if (location.hasOwnProperty('geometry'))
        {
            // @location is an actual google.maps.location object
            var
            _position = {
                'lat': location.geometry.location.lat(),
                'lng': location.geometry.location.lng()
            };
            this._destination = _position;
            this.destinationPlaceId = location.place_id;
            if (this._debug) console.log('Current Destination: ' + location.formatted_address);
        } else
        {
            // @location is merely a position objekt ( f.e. { lat: 11111, lng: 22222 })
            // this._destination = location;
            this.destinationPlaceId = null;

            var
            _this = this,
            _address = this.options.locations[0],
            _markerOptions = {
                position: location,
                icon: this.utils.pin,
                descr: _address,
                title: 'Weigelstein',
                onInfoWindowClose: function(iw) {
                    _this.g.markerInfoWindow = null;
                }
            },
            _marker = null;

            if (!!this._destination)
    				{
                this.utils.Geocoder.geocode({'location': this._destination }, function(results, status)
                {
                    if (status === _this.api.GeocoderStatus.OK)
                    {
                        if (results[0])
                        {
                            _this.destinationPlaceId = results[0].place_id;
                            _markerOptions.descr = results[0].formatted_address;
                            _marker = _this.utils.createMarker(_markerOptions);
                        } else {
        										window.alert('Geocoder failed due to: ' + status);
        								}
                    }
                });
            } else
            {
                _this._destination = location;
                _marker = _this.utils.createMarker(_markerOptions);
            }
            _this.g.defaultMarker = _marker;
            _this.g.defaultCenter = _this._destination;
        }
    }

    // Origin-Place-Id
    get originPlaceId() { return this._originPlaceId; }
    set originPlaceId(id) {
        if (this._debug) console.log(`GmapUI set originPlaceId w/ id = ${id}`);
        this._originPlaceId = id;
        if (null !== this._originPlaceId) {
            var _this = this;
            this.utils.getPlaceDetails(this._originPlaceId, {
                onResolve: function(data, status) {
                    if (_this._debug) console.log(`GmapUI::set originPlaceId => getPlaceDetails::onResolve w/ status = ${status}`);
                    if (_this._debug) console.log(data.formatted_address);
                    _this.originPlaceDetails = data;
                },
            });

            if (!!this.travelMode && !!this.destinationPlaceId)
            {
                // Display route if all necessary params exist
                this.utils.calculateAndDisplayRoute(this.originPlaceId, this.destinationPlaceId);
            }
        }
    }

    // Destination-Place-Id
    get destinationPlaceId() { return this._destinationPlaceId; }
    set destinationPlaceId(id) {
        if (this._debug) console.log(`GmapUI set destinationPlaceId w/ id = ${id}`);
        this._destinationPlaceId = id;
        if (null !== this._destinationPlaceId)
        {
            var _this = this;
            this.utils.getPlaceDetails(this._destinationPlaceId, {
                onResolve: function(data, status) {
                    if (_this._debug) console.log(`GmapUI::set destinationPlaceId => getPlaceDetails::onResolve w/ status = ${status}`);
                    if (_this._debug) console.log(data.formatted_address);
                    _this.destinationPlaceDetails = data;
                },
            });

            if (!!this.travelMode && !!this.originPlaceId)
            {
                // console.log(this.originPlaceId);
                // Display route if all necessary params exist
                this.utils.calculateAndDisplayRoute(this.originPlaceId, this.destinationPlaceId);
            }
        }
    }

    // Origin-Place-Details
    get originPlaceDetails() { return this._originPlaceDetails; }
    set originPlaceDetails(details) { this._originPlaceDetails = details; }

    // Destination-Place-Details
    get destinationPlaceDetails() { return this._destinationPlaceDetails; }
    set destinationPlaceDetails(details) { this._destinationPlaceDetails = details; }

    // Travel-Mode
    get travelMode() { return this._travelMode; }
    set travelMode(mode) {
        if (this._debug) console.log(`GmapUI set travelMode w/ mode = ${mode}`);
        this._travelMode = mode;
        if (!!this._travelMode)
        {
            if (this.destinationPlaceId && this.originPlaceId)
            {
                // Display route if all necessary params exist
                this.utils.calculateAndDisplayRoute(this.originPlaceId, this.destinationPlaceId);
            }
        }
    }
}

export default GmapUI;

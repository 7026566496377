/**!
 *  IMAGE-SLIDER-MODAL
 *
 *  @name			      image-slider-modal.js (js/ws/image-slider-modal.js)
 *
 *  @package       JOOMLA BASE THEME
 *  @description	 Custom implementation of kenwheeler's slick.js that opens in a TWBS (v5.1.x) Modal from an existing slider (lightbox-mode).
 *                 By passing the slider as jquery-html-object - before it's initialisation - into the constructor, a TWBS (5.1.x) modal
 *                 is created, which initializes an enlarged copy of the original slider on 'SHOW' in it's body. On 'HIDE' the modal-slider is removed
 *                 (destroyed). Slides of the original slider should be clickable, triggering the modal w/ id = '[original-slider-id]_Modal'. The
 *                 'trigger' should have an attribute: 'data-index="[index of the slide]"' and the image-tag should have an attribute:
 *                 'data-img-modal="[path/to/the/larger/modal-image.jpg]"'.
 *  @copyright 	   (c) 2022 Ansgar Hiller <ansgar@weigelstein.de>
*/

import $ from 'jquery';
import 'slick-carousel';
import { Modal } from 'bootstrap';

class ImageSliderModal
{
		constructor(slider, options = {}, debug = false)
		{
				if (typeof slider !== 'object') { return false; }

        if (debug) console.log(`ImageSliderModal:constructor (js/ws/image-slider-modal.js)`);

        this._debug = debug;
        this._parentslider = slider;
				this._id = this._parentslider.attr('id') + '_Modal';
        this._element = slider;
				this._opt = {
						// Modal native options
						backdrop: 'staic',
						keyboard: true,
						focus: true,
            // Custom options
            title: null,
						showCloseBtn: true,
						size: 'xl',
				};
				$.extend(this._opt, this._element.data());

				this._html = slider.clone();
				this._modal = null;
				this._modalBody = null;
				this._slider = null;
        this._spinner = $('<div class="loader spinner-border text-light" role="status"/>')
						.append(
								$('<span class="visually-hidden">Loading...</span>')
						)
				;

				if (typeof this._parentslider.slick === 'function')
				{
						this._init();
				} else {
						if (debug) console.log('ERROR: slick-carousel not imported');
				}
		}

		_init()
		{
				if (this._debug) console.log(`ImageSliderModal:_init w/ id = ${this._id} (js/ws/image-slider-modal.js)`);

				var _this = this;
				this._modal = this.createModal();
				this._el = document.getElementById(this._id);
				this._el.addEventListener('show.bs.modal', 		function (e) { _this._show(e); });
				this._el.addEventListener('shown.bs.modal', 	function (e) { _this._shown(e); });
				this._el.addEventListener('hide.bs.modal', 		function (e) { _this._hide(e); });
				this._el.addEventListener('hidden.bs.modal', 	function (e) { _this._hidden(e); });
		}

		_show(e)
		{
				if (this._debug) console.log(`ImageSliderModal:_show w/ id = ${this._id} (js/ws/image-slider-modal.js)`);

				this._slider = this.createSlider();
				this._modalBody.append(this._slider);
		}

		_shown(e)
		{
        let
        _this = this,
        _trigger = $(e.relatedTarget),
        _index = _trigger.data('index'),
        _slider = this._slider,
        _options = {
            lazyLoad: 'ondemand',  // 'ondemand' or 'progressive'
            arrows: (!APP.useSmallScreenBehavior),
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            centerMode: false,
            autoplay: true,
            fade: false,
            cssEase: 'linear',
            initialSlide: _index,
        };

				if (this._debug) console.log(`ImageSliderModal:_shown w/ id = ${this._id} and slide-index = ${_index} (js/ws/image-slider-modal.js)`);

        _slider
						.on(
								{
										         'init': _this._sliderInit.bind(this),
										   'lazyLoaded': _this._lazyLoaded.bind(this),
										  'afterChange': _this._afterChange.bind(this),
										 'beforeChange': _this._beforeChange.bind(this)
								}
						)
				;
				_slider.slick(_options);
		}

		_hide(e)
		{
				if (this._debug) console.log(`ImageSliderModal:_hide w/ id = ${this._id} (js/ws/image-slider-modal.js)`);
		}

		_hidden(e)
		{
				if (this._debug) console.log(`ImageSliderModal:_hidden w/ id = ${this._id} (js/ws/image-slider-modal.js)`);

        this._modalBody.empty();
        this._slider.slick('unslick');
				this._slider = null;

        let _id = '#slide-' + this.currentSlideId;
		}

    /*
		* SLIDER EVENTS
		* ============================== */

		_sliderInit(e, slick)
		{
				if (this._debug) console.log(`Slide: ${$(this.getSlide(slick.currentSlide)).data('title')}`);

        this._currentSlide = slick.currentSlide;

        // this.getSlideData();
		}

		_lazyLoaded(e, slick, image, imageSource)
		{
				if (this._debug) console.log(`ImageSliderModal:_lazyLoaded w/ src = ${imageSource} (js/ws/image-slider-modal.js)`);

				image.removeClass('loading').addClass('done');
		}

		_afterChange(e, slick, currentSlide)
		{
				if (this._debug) console.log(`ImageSliderModal:_afterChange w/ currentSlide = ${currentSlide} (js/ws/image-slider-modal.js)`);

				if (this._debug) console.log(`Previos: ${$(this.getSlide(currentSlide - 1)).data('id')}`);
        if (this._debug) console.log(`Current: ${$(this.getSlide(currentSlide)).data('id')}`);
				if (this._debug) console.log(`Next:    ${$(this.getSlide(currentSlide + 1)).data('id')}`);

        this._currentSlide = currentSlide;

        // this.getSlideData();

		}

    _beforeChange(e, slick, currentSlide, nextSlide)
		{
				if (this._debug) console.log(`ImageSliderModal:_beforeChange w/ nextSlide = ${nextSlide} (js/ws/image-slider-modal.js)`);
		}

		/*
		* HTML BUILDER
		* ============================== */

		createModal()
		{
				if (null !== this._modal) return this._modal;
				if (this._debug) console.log(`ImageSliderModal:createModal w/ id = ${this._id} (js/ws/image-slider-modal.js)`);

				var
				_dialog 	= $('<div class="modal-dialog modal-dialog-centered modal-'+ this._opt.size +'" />'),
				_content 	= $('<div class="modal-content" />'),
				_header 	= $('<div class="modal-header" />'),
				_body 		= $('<div class="modal-body" />'),
        _footer 	= $('<div class="modal-footer" />'),
				_close    = $('<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>'),
				_title    = $('<h5 class="modal-title" id="'+ this._id +'Label">'+ this._opt.title +'</h5>'),
				_modal 		= $('<div class="modal fade image-slider-modal" id="'+ this._id +'" tabindex="-1" aria-labelledby="'+ this._id +'Label" aria-hidden="true"/>')
						.append(
								_dialog.append(
										_content.append(_body).append(_footer)
								)
						)
				;

				if (null !== this._opt.title || this._opt.showCloseBtn)
				{
						_content.prepend(_header);
						if (null !== this._opt.title) _header.append(_title);
						if (this._opt.showCloseBtn) 	_header.append(_close);
				}
				this._modalBody   = _body;
        this._modalFooter = _footer;

				$('body').append(_modal);

				return new Modal(_modal);
		}

		createSlider(index)
		{
				if (null !== this._slider) return this._slider;

        if (this._debug) console.log(`ImageSliderModal:createSlider w/ id = ModalSlider_${this._id} (js/ws/image-slider-modal.js)`);

        let
				_this = this,
				_slider = $('<div id="ModalSlider_'+ this._id +'" class="slider slick image-slider modal-slider pb-0" data-type="modalSlider"/>'),
        _slides = (this._html.find('.inner').length) ? this._html.find('.inner').clone() : false;


        if (_slides)
        {
            _slides.each(function(i,el) {
                let
								_loader = _this._spinner.clone(),
                _img = $(el).find('img'),
                _src = (_img.data('img-modal')) ? _img.data('img-modal') : _img.data('lazy'),
                _image = $('<img data-lazy="'+ _src +'"/>')
                    .addClass('figure-img img-fluid mx-auto')
                    .attr({
                        'alt': _img.attr('alt')
                    });
                if (_img.parent().is('button')) _img.unwrap();
                _img.replaceWith(_image);

								$(el).prepend(_loader);
                _slider.append($(el));
            });
        }

				this._slides = _slides;

				return _slider;
		}

    /*
		* GETTER
		* ============================== */

		getSlide(index = 0)
		{
				return this._slides[index];
		}

    getSlideData()
		{
        let data = {};
        data.id = $(this.getSlide(this.currentSlide)).data('id'),
        data.fbEnabled = $(this.getSlide(this.currentSlide)).data('fb-enabled');
        data.twEnabled = $(this.getSlide(this.currentSlide)).data('tw-enabled');

        let
        url = '/index.php'
            + '?format=json'
            + '&option=com_ajax'
            + '&template=waidblicke'
            + '&task=getdetailpage'
            + '&ignoreMessages=0',
        options = {
                 url: url,
                data: data,
                type: 'post',
               async: true,
             context: this,
            dataType: 'json'
        };

        $.ajax(options)
        .done(function(response)
        {
            let _data = JSON.parse(response.data);

            console.log(_data);

            if (_data.fb_enabled === '1' || _data.tw_enabled === '1') {

                this._modalFooter.empty().append($(_data.html));

                if (typeof SIC === 'object') { SIC.wireChildren(this._modalFooter); }
            } else {
                this._modalFooter.empty();
            }

        });
		}

    get currentSlide()    { return this._currentSlide; }

    get currentSlideId()  { return $(this.getSlide(this.currentSlide)).data('id'); }
}

export default ImageSliderModal;


import rmDefault  from './rm-default';
import rmNight    from './rm-night';
import rmRetro    from './rm-retro';

class Roadmap
{
		static getStyledMapType(api, style = 'default')
		{
        switch(style)
        {
            case 'night':
						case 'dark':
                return new api.StyledMapType(rmNight, 	{ name: 'Roadmap-Night' });
            break;
            case 'retro':
                return new api.StyledMapType(rmRetro, 	{ name: 'Roadmap-Retro' });
            break;
            case 'default':
						case 'light':
            default:
                return new api.StyledMapType(rmDefault, { name: 'Roadmap-Default' });
            break;
        }
		}
}

export default Roadmap;

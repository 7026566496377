/**!
 *  @name				   base.js (.dev/js/ws/base.js)
 *
 *  @package       COMPONENTS
 *  @description	 tba.
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 */

import $ from 'jquery';
import 'jquery-ui';
import 'waypoints/src/waypoint.js';
import enquire from 'enquire.js';
import Cookies from 'js-cookie';
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

require('bowser/bundled');
import Bowser from 'bowser';
import Hoverable from './hoverable';

const DEVICE = Bowser.parse(window.navigator.userAgent);

class Base
{
		/*
		 *	READ-ONLY
		 * 	====================== */

		get BP()
		{
				return BP;
		}
		get HEADER_HEIGHTS()
		{
				return HEADER_HEIGHTS;
		}
		get GRID_FLOAT_BREAKPOINT() { return GRID_FLOAT_BREAKPOINT; }
		get HERO_RATIO_DEFAULT() 		{ return HERO_RATIO_DEFAULT; }
		get HERO_RATIOS() 					{ return HERO_RATIOS; }
		get HERO_MAX_HEIGTHS() 			{ return HERO_MAX_HEIGTHS; }
		get ANIMATION_TYPE() 				{ return ANIMATION_TYPE; }
		get BODY() 			{ return $('body'); }
		get HTML() 			{ return $('html'); }
		get PAGE() 			{ return $('#page') 		|| []; }
		get HEADER() 		{ return $('#header') 	|| []; }
		get HERO() 			{ return $('#hero') 		|| []; }
		get MAIN() 			{ return $('#main') 		|| []; }
		get SIDEBAR() 	{ return document.getElementById('sidebar') 	|| []; }
		get SB_BURGER()	{ return $('[name="toggle-sidebar-button"]')	|| []; }
		get NAVBAR() 		{ return document.getElementById('navbar')  	|| []; }
		get NB_BURGER()	{ return $('[name="toggle-navbar-button"]')		|| []; }
		get FOOTER() 		{ return $('#footer') 	|| []; }
		get PAGERS() 		{ return this._pagers; }
		get w() 				{ return Waypoint.viewportWidth(); }
		get h() 				{ return Waypoint.viewportHeight(); }
		get scrollTop() { return $(window).scrollTop(); }
		get isiOS() 		{ return (DEVICE.os.name.toLowerCase() === 'ios'); }
		get isAndroid() { return (DEVICE.os.name.toLowerCase() === 'android'); }
		get isMobile() 	{ return (DEVICE.platform.type === 'mobile'); }
		get isMobile() 	{ return (DEVICE.platform.type === 'tablet'); }
		get isFirefox() { return (DEVICE.browser.name.toLowerCase() === 'firefox'); }
		get headerHeight() { return this.HEADER_HEIGHTS[this._currentBP]; }
		get heroHeight() {
				let _h = (this.w * this.heroRatio);
				_h = (_h <= this.HERO_MAX_HEIGTHS[this._currentBP])? _h : this.HERO_MAX_HEIGTHS[this._currentBP];
				return _h;
		}
		get heroRatio() 	{ return this.HERO_RATIOS[this._currentBP]; }
		get currentBP() 	{ return this._currentBP; }
		get orientation() { return (this.w > this.h) ? 'landscape' : 'portrait'; }

		get useSmallScreenBehavior() { return (this.w < this.GRID_FLOAT_BREAKPOINT); }

		/*
		 *	GETTER/SETTER
		 * 	====================== */

		/*
		 *	App::isSidebarExpanded [boolean]
		 */
		get isSidebarExpanded()
		{
				if (typeof Cookies.get('sidebar-aria-expanded') !== 'undefined')
				{
						this._isSidebarExpanded = (Cookies.get('sidebar-aria-expanded') === 'true')? true : false;
				} else {
						Cookies.set('sidebar-aria-expanded', this._isSidebarExpanded.toString());
				};

				return this._isSidebarExpanded;
		}
		set isSidebarExpanded(bol)
		{
				this._isSidebarExpanded = bol;
				this._sidebarBtn.attr('aria-expanded', this._isSidebarExpanded.toString());
				Cookies.set('sidebar-aria-expanded', this._isSidebarExpanded.toString());
		}

		get backdropElement()
		{
				return this._backdrop_tmp;
		}
		set backdropElement(ele)
		{
				if (typeof ele === 'object')
				{
						this._backdrop_tmp = ele.clone();
				} else
				if (false === ele && typeof this._backdrop_tmp === 'object')
				{
						this._backdrop_tmp.remove();
						this._backdrop_tmp = false;
				}
		}

		constructor(debug = false)
		{
				if (debug) console.log(`App::constructor`);

				this._debug = debug;
				this._doc = $(document);
				this._currentBP = null;
				this._scrollTop = 0;
				this._lastScrollTop = 0;
				this._sidebar = ($(this.SIDEBAR).length) ? bootstrap.Offcanvas.getOrCreateInstance(this.SIDEBAR) : false;
				this._sidebarBtn = $('button[name="toggle-sidebar-button"]').first() || false;

				if (!this._sidebarBtn)
				{
						this.PAGE.addClass('sidebar-hidden');
				}

				this._pagers = {};
				this._backdrop = $('<div/>').addClass('backdrop fade spinner spinner-bs');
				this._naviBackdrop = $('<div/>').addClass('backdrop navi-backdrop fade');
				this._backdrop_tmp = false;

				this._onStartEvents = $.Callbacks();
				this._onBreakpointChangeEvents = $.Callbacks();
				this._onRefreshEvents = $.Callbacks();
				this._onRefreshOnceEvents = $.Callbacks();
				this._onScrollStartEvents = $.Callbacks();
				this._onScrollEndEvents = $.Callbacks();

				this._w = this.w;
				this._h = this.h;

				this.BODY.addClass(DEVICE.os.name.toLowerCase() + ' ' + DEVICE.platform.type + ' ' + DEVICE.browser.name.toLowerCase());

				enquire.register('(min-width: ' + ( this.BP.xxl ) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'xxl')
				});
				enquire.register('(min-width: ' + ( this.BP.xl ) + 'px) and (max-width: ' + ( this.BP.xxl - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'xl')
				});
				enquire.register('(min-width: ' + ( this.BP.lg ) + 'px) and (max-width: ' + ( this.BP.xl - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'lg')
				});
				enquire.register('(min-width: ' + ( this.BP.md ) + 'px) and (max-width: ' + ( this.BP.lg - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'md')
				});
				enquire.register('(min-width: ' + ( this.BP.sm ) + 'px) and (max-width: ' + ( this.BP.md - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'sm')
				});
				enquire.register('(max-width: ' + ( this.BP.sm - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'xs')
				});

				$(window)
						.on(
								{
										'resize': this._resize.bind(this),
										'scroll': this._scroll.bind(this),
										'orientationchange': this._orientationChange.bind(this)
								}
						)
				;

				this._resizeTimer = null;
				this._scrollTimer = null;
				this._isScrolling = false;

				this._IMAGES = []; // contains all images of last App::preloadImages call
				this._BROKEN = []; // contains all broken images of last App::preloadImages call

				this._init();
		}

		/**!
		 *	private method called when a TWBS breakpoint changed due to window resizing
		 *	@param: bp [string] -> 'xxl', 'xl', 'lg' ...
		 */
		_onBreakpointChange(bp)
		{
				if (this._debug) console.log(`App::_onBreakpointChange w/ param: bp = ${bp}`);

				this._currentBP = bp;
				this.BODY.removeClass('xs sm md lg xl xxl').addClass(bp);

				this.swapImagesByBreakpoint();
				this.swapBgImagesByBreakpoint();

				$(window).trigger('breakpointchange');

				this._onBreakpointChangeEvents.fire(this);
		}

		/**!
		 *	window.resize listener
		 *	@param: e [object] -> window.event
		 */
		_resize(e)
		{
				if (this._debug) console.log(`App::_resize (native window.resize)`);

				clearTimeout(this._resizeTimer);

				this._refresh();

				this._resizeTimer = setTimeout(this._delayedResize.bind(this), 250);
		}

		/**!
		 *	window.orientationChange listener
		 *	@param: e [object] -> window.event
		 */
		_orientationChange(e)
		{
				let _neworientation = (this.orientation === 'landscape')? 'portrait':'landscape';

				if (this._debug) console.log(`App::_orientationChange (native window.orientationchange)`);
				if (this._debug) console.log(`Event: ${e.type} (${_neworientation})`);
		}

		/**!
		 *	window.resize listener fired only once
		 *	@param: e [object] -> window.event
		 */
		_delayedResize(e)
		{
				if (this._debug) console.log(`App::_delayedResize`);

				let
				_w = this._w,
				_h = this._h,
				_dw = Math.abs(APP.w - _w),
				_dh = Math.abs(APP.h - _h);

				if (this._debug) console.log(this.orientation);

				if(_h > 0 && (_dw == 0 && _dh <= RESIZE_HEIGHT_THRESHOLD))
				{
						if (this._debug) console.log('Abort window resize');

						return false;
				}

				this._refreshOnce();

				$(window).trigger('delayed-resize',e);
		}

		/**!
		 *	window.scroll listener
		 *	@param: e [object] -> window.event
		 */
		_scroll(e)
		{
				if (this._debug) console.log(`App::_scroll (native window.scroll)`);

				let
				_this = this,
				_scroll = $(document).scrollTop();

				if (!this._isScrolling)
				{
						this._isScrolling = true;
						this._scrollStart(e);
				}
				clearTimeout(this._scrollTimer);
				this._scrollTimer = setTimeout(function(e)
						{
		            _this._scrollEnd(e);
		            _this._isScrolling = false;
		        }, 100
				);

				if (_scroll >= this.heroHeight)
				{
						this.BODY.addClass('scrolled-past-header');
				} else {
						this.BODY.removeClass('scrolled-past-header');
				}

		}

		/**!
		 *	window.scroll listener fired when scroll starts
		 *	@param: e [object] -> window.event
		 */
		_scrollStart(e)
		{
				if (this._debug) console.log(`App::_scrollStart`);

				this.BODY.addClass('scrolling');
				this._lastScrollTop = this._scrollTop;

				$(window).trigger('scroll-start',e);

				this._onScrollStartEvents.fire(this);
		}

		/**!
		 *	window.scroll listener fired when scroll ends
		 *	@param: e [object] -> window.event
		 */
		_scrollEnd(e)
		{
				if (this._debug) console.log(`App::_scrollEnd`);

				this.BODY.removeClass('scrolling');
				this._scrollTop = $(window).scrollTop();

				$(window).trigger('scroll-end',e);

				this._onScrollEndEvents.fire(this);
		}

		/**!
		 * 	App::_init
		 *
		 *	Basically wires all interactive elements
		 */
		_init()
		{
				if (this._debug) console.log(`App::_init`);

				this.backdrop('load');

				let
				_this = this,
				_images = (this.HERO.length) ?
						this.HERO.find('.slide-container').first() : $('#content');

				// Make sure, sidebar doesn't stay open on phones
				if (this.useSmallScreenBehavior) this.isSidebarExpanded = false;


				this._preloadImages({
						bg: true,
						onAlways: this._start.bind(this)
				}, _images);

				this._wireChildren(this._doc);
		}

		/**!
		 * 	App::_wireChildren
		 *
		 *  Wire interactive dom-elements (call when dom was updated/replaced f.e. by an ajax-injection)
		 *	@param: parent [object]
		 */
		_wireChildren(parent)
		{
				if (this._debug) console.log(`App::_wireChildren w/ parent = ${parent[0]}`);

				let _this = this;

				/**
				 *  HOVERABLES
				 */
				var _hoverables = parent.find('.hoverable');
		    if (_hoverables.length)
		    {

if (this._debug) console.log(`
		WIRING HOVERABLES (.hoverable) \n
		================================================ \n
		${_hoverables.length} items found \n
`);

		        Hoverable(_hoverables);
		    }

				/**
				 *  TOGGLE-BUTTONS
				 */
				var _toggle = parent.find('[data-toggle]');
				if (_toggle.length)
				{
						_toggle.each(function(i,el)
								{
										var
										_btn    = $(el),
										_target = _btn.data('bs-target') || _btn.attr('href'),
										_type   = _btn.data('bs-toggle');

if (this._debug) console.log(`
    WIRING TOGGLE-BUTTON ([data-toggle]) \n
		================================================ \n
		type:						${_type} \n
		target:					${_target} \n
`);

										switch(_type)
										{
												case 'collapse':
														// COLLAPSIBLES (are taken care of by TWBS)
														break;

												case 'dropdown':
		                        // DROPDOWNS (are taken care of by TWBS)
		                        break;

												case 'modal':
		                        // MODALS (are taken care of by TWBS)
		                        break;

		                    case 'popover':
		                        // console.log(_btn);
		                        _btn.popover();
		                        break;
										}
								}
						);
				}

				/**
				 *  LINKED-ITEM
				 */
				var _linkeditems = parent.find('[data-link]');
				if (_linkeditems.length)
				{
						_linkeditems.each(function(i,el)
								{
										$(el).on('click', function(e)
												{
														var
														_url = $(this).data('link'),
														_target = $(this).data('target') || '_self';

														window.open(_url,_target);
												}
										);
								}
						);
				}

				/**
				 *  EXPORT-CSV-BUTTONS
				 */
				var _csv = parent.find('.js-export-csv');
				if (_csv.length)
				{
						_csv.each(function(i,el)
								{

if (this._debug) console.log(`
    WIRING EXPORT-CSV-BUTTONS (.js-export-csv) \n
		================================================ \n
		${_csv.length} items found \n
`);

										$(el).on('click', function()
												{
														var
														_url = $(this).data('url'),
														_filename = $(this).data('filename') || 'csv-export';
														_filename += '.csv';

if (this._debug) console.log(`
    EXPORT-CSV-BUTTON *CLICKED* (.js-export-csv) \n
		================================================ \n
		url:						${_url} \n
		filename:				${_filename} \n
`);
														$.ajax(
																{
																					url: encodeURI(_url),
																				 type: 'GET',
																	contentType: 'text/csv'
																}
														).then(
																(data, jqXHR, opt) => {
																		var _contentType = opt.getResponseHeader('content-type');

																		_this.downloadCSV(data, _filename, _contentType);
																}
														);
												}
										);
								}
						);
				}
		}

		/**!
		 * 	App::_refresh
		 *
		 *  Add dom-manipulations to occur on native window.resize (fired constantly during resizing)
		 */
		_refresh()
		{
				if (this._debug) console.log(`App::_refresh`);

				/* SIDEBAR */
				if (this.SIDEBAR.length)
				{
						this.SIDEBAR.css({
								height: (this.h - this.HEADER_HEIGHTS[this._currentBP]) + 'px'
						});
				}

				this._onRefreshEvents.fire(this);
		}

		/**!
		 * 	App::_refreshOnce
		 *
		 *  Add dom-manipulations to occur on window.delayed-resize (fired only once after resizing finished)
		 */
		_refreshOnce()
		{
				if (this._debug) console.log(`App::_refreshOnce`);
				this._w = this.w;
				this._h = this.h;

				this._onRefreshOnceEvents.fire(this);
		}

		/**!
		 * 	App::_start
		 *
		 *  called when all images are loaded
		 */
		_start()
		{
				if (this._debug) console.log(`App::_start`);
				this.BODY.removeClass('no-js');

				/**
				 *  SIDEBAR
				 */
				if (this._sidebar)
				{
						if (this._debug) console.log(`App::_start -> wire SIDEBAR-BUTTON`);
						if (this._sidebar._isShown)
						{
								this.PAGE.addClass('sidebar-shown');
						} else {
								this.PAGE.addClass('sidebar-hidden');
						}
				}

				this._refreshOnce();

				this._onStartEvents.fire(this);

				// remove backdrop
				gsap.delayedCall(1, this.backdrop.bind(this), ['hide']);
		}

		/**!
		 * 	App::_preloadImages
		 *
		 *  preloads all images and tells you when it's done (ImagesLoaded.js)
		 *	@see: 		https://imagesloaded.desandro.com
		 * 	@params: 	options [object] =>
		 *			{
		 *							 bg [boolean] (default false),
		 *				 onAlways [function|null] (default null),
		 *				onSuccess [function|null] (default null),
		 *					 onFail [function|null] (default null),
		 *			 onProgress [function|null] (default null),
	 	 *			}
		 *	@param: parent [object] (default $(body))
		 */
		_preloadImages(options, parent)
		{
				if (typeof options === 'undefined') var options = {};
				if (typeof parent === 'undefined') var parent = this.BODY;

				if (this._debug) console.log(`APP::_preloadImages w/ parent = ${parent[0]}`);

				this._IMAGES = [];
				this._BROKEN = [];

				var
				_this = this,
				_opt = {
						// callbacks
						bg: 		    options.bg 			     || false,
						onAlways:	  options.onAlways  	 || null,
						onSuccess: 	options.onSuccess  	 || null,
						onFail:    	options.onFail		   || null,
						onProgress:	options.onProgress	 || null
				},
				_events = $.Callbacks(),
				_dispatch = function ( fn, params, image ) {
						if ( typeof fn === 'function' ) {
								_events
										.add(fn)
										.fire( params, image )
										.remove(fn);
						}
				};
				$.extend(_opt, options);

				var _parent = (_opt.bg)? parent.find('.bg-img') : parent;

				_parent.imagesLoaded({background: _opt.bg}
						).always(
								function(obj) {
										if (_this._debug) console.log(`APP::_preloadImages fired "onAlways"`);
										_dispatch(_opt.onAlways,obj,null);
								}
						).done(
								function(obj) {
										if (_this._debug)
										{
												console.log(`APP::_preloadImages fired "onSuccess"`);
												console.log(_this._IMAGES);
										}
										_dispatch(_opt.onSucess,obj,null);
								}
						).fail(
								function(obj) {
										if (_this._debug) console.log(`APP::_preloadImages fired "onFail"`);
										_dispatch(_opt.onFail,obj,null);

										if (_this._debug) console.log(`WARNING: ${_this._BROKEN.length} images are broken or missing:`);
				      			if ( _this._BROKEN.length ) {
				        				var _missing = {};
				        				for (var i = 0; i < _this._BROKEN.length; i++) {
				        					  _missing[(i+1)] = _this._BROKEN[i].img.src;
				        				}
				        				if (_this._debug) console.log(_missing);
				      			}
								}
						).progress(
								function(obj, image) {
										if (_this._debug) console.log(`APP::_preloadImages fired "onProgress" w/ image ${$(image.img).attr('src')}`);
										_dispatch(_opt.onProgress,obj,image);

										if (!image.isLoaded) //	mend broken images
				      			{
				        				//	remove 'ready' state
				        				$(image.img)
				          					.removeClass('ready')
				          					.parent()
				          					.removeClass('ready');

				        				if ($(image.img).hasClass('generated'))
				        				{
				        					  $(image.img).remove(); // throw out if broken image was dynamically generated ...
				        				} else
				        				{
				        					  $(image.img).addClass('hidden'); // ... or hide
				        				}
				        				// collect broken images
				        				_this._BROKEN.push(image);
				      			} else
				      			{	// collect good images
				      				  _this._IMAGES.push(image);
				      			}
								}
						)
				;
		}

		/**!
		 * 	App::on
		 *
		 *	Add callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		on(event, fn)
		{
				if (typeof fn === 'function')
				{
						switch(event) {
								case 'start':
										this._onStartEvents.add(fn);
										break;
								case 'breakpointChange':
										this._onBreakpointChangeEvents.add(fn);
										break;
								case 'refresh':
										this._onRefreshEvents.add(fn);
										break;
								case 'refreshOnce':
										this._onRefreshOnceEvents.add(fn);
										break;
								case 'scrollStart':
										this._onScrollStartEvents.add(fn);
										break;
								case 'scrollEnd':
										this._onScrollEndEvents.add(fn);
										break;
						}
				}
		}

		/**!
		 * 	App::off
		 *
		 *	Remove callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		off(event, fn)
		{
				if (typeof fn === 'function')
				{
						switch(event) {
								case 'start':
										this._onStartEvents.remove(fn);
										break;
								case 'breakpointChange':
										this._onBreakpointChangeEvents.remove(fn);
										break;
								case 'refresh':
										this._onRefreshEvents.remove(fn);
										break;
								case 'refreshOnce':
										this._onRefreshOnceEvents.remove(fn);
										break;
								case 'scrollStart':
										this._onScrollStartEvents.remove(fn);
										break;
								case 'scrollEnd':
										this._onScrollEndEvents.remove(fn);
										break;
						}
				}
		}

		/*
		 *  Helper to calc scroll-animation durations
		 */
		getWindowHeight(_h)
		{
				if (_h) {
						return this.h + _h;
				} else {
						return this.h;
				}
		}

		/*
		 *  Helper to calc hero-element heights
		 */
		getHeroHeight(_ratio)
		{
				return (this.h < HERO_MAX_HEIGTHS[this._currentBP])? Math.round(this.h) : HERO_MAX_HEIGTHS[this._currentBP];
		}

		/**!
		 * 	App::off
		 *
		 *	Remove callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		backdrop(mode = 'load')
		{
				if (this._debug) console.log(`App::backdrop w/ mode = ${mode}`);
				switch(mode) {
						case 'load':
						if (this.backdropElement.length) return;
								this.backdropElement = this._backdrop;
								this.BODY
										.addClass('backdrop-open')
										.append(this.backdropElement);
								this.backdrop('show');
						break;
						case 'navi':
								if (this.backdropElement.length) return;
								this.backdropElement = this._naviBackdrop;
								this.BODY
										.addClass('backdrop-open')
										.append(this.backdropElement);
						break;
						case 'show':
								this.backdropElement
										.addClass('show');
						break;
						case 'hide':
								this.backdropElement.removeClass('show');
								this.BODY.removeClass('backdrop-open');
								gsap.delayedCall(1, this.backdrop.bind(this), ['remove']);
						break;
						case 'remove':
								this.backdropElement = false;
						break;
				}
		}

		/**!
		 * 	App::swapImagesByBreakpoint
		 *
		 *  Replaces image src w/ appropriate src string from data attributes according to this.currentBP
		 *	F.e.: <img src="path/to/img.jpg" data-xxl="path/to/img_xxl.jpg" data-xl="... />
		 *	Is typically called by App::_onBreakpointChange or App::_refreshOnce
		 */
		swapImagesByBreakpoint(parent)
		{
				if (typeof parent === 'undefined') var parent = this.BODY;

				if (this._debug) console.log(`App::swapImagesByBreakpoint w/ parent = ${parent}`);

				var
				_this = this,
				_bp = this.currentBP,
				_parent = parent;

				_parent.find('img').each(function(i,el)
						{
								var _original = $(el).data('lazy') || $(el).attr('src');

								if (!$(el).data('original'))
								{
										$(el).data({
												original : _original
										});
								}

								var _newSrc = ( $(el).data(_bp) !== undefined ) ? $(el).data(_bp) : $(el).data('original');

								if ( $(el).attr('src') )
								{
										$(el)
												.removeClass('done')
												.addClass('loading')
												.attr({
														src: _newSrc
												})
												.imagesLoaded()
												.progress(function(obj,image)
												{
													if (_this._debug) console.log(`App::swapImagesByBreakpoint w/ bp = ${_bp} => progress`);

													$(image.img)
															.attr('height',(image.img.naturalHeight > 0) ? image.img.naturalHeight : '100%')
															.attr('width',(image.img.naturalWidth > 0) ? image.img.naturalWidth : '100%')
															.addClass('done')
															.removeClass('loading');
												})
												.done (function(obj)
												{
														if (_this._debug) console.log(`App::swapImagesByBreakpoint w/ bp = ${_bp} => done`);
														if (_this._debug) console.log($(obj.elements));
												})
										;
								} else {
										$(el).data('lazy',_newSrc);
								}
						}
				);
		}

		/**!
		 * 	App::swapBgImagesByBreakpoint
		 *
		 *	Same as above, only for background-images f.e.: <div style="background-image:url(path/to/img.jpg);" />
		 */
		swapBgImagesByBreakpoint(elements)
		{
				if (typeof elements === 'undefined') var elements = this.MAIN.find('.bg-img');

				if (this._debug) console.log(`App::swapBgImagesByBreakpoint w/ elements = ${elements.length}`);

				var
				_bp = this.currentBP,
				_elements = elements;

				_elements.each(function(i,el)
						{
								$(el).addClass('loading');
								$(el)
										.imagesLoaded({background: true})
										.done(function(obj) {
												$(obj.elements).removeClass('loading');
										});

								if ( $(el).data(_bp) === undefined )
								{
										$(el).css({'background-image' : 'url(' + $(el).data('lazy') + ')'});
								} else {
										$(el).css({'background-image' : 'url(' + $(el).data(_bp) + ')'});
								}
						}
				);
		}

		/*
		 *	PUBLIC METHODS (Interface)
		 */

		 /**!
 		 * 	App::preloadImages
 		 *
 		 *  preloads all images and tells you when it's done (ImagesLoaded.js)
 		 *	@see: 		https://imagesloaded.desandro.com
 		 * 	@params: 	options [object] =>
 		 *			{
 		 *							 bg [boolean] (default false),
 		 *				 onAlways [function|null] (default null),
 		 *				onSuccess [function|null] (default null),
 		 *					 onFail [function|null] (default null),
 		 *			 onProgress [function|null] (default null),
 	 	 *			}
 		 *	@param: parent [object] (default $(body))
 		 */
		preloadImages(options, parent)
		{
				this._preloadImages(options, parent);
		}

		/**!
		 * 	App::wireChildren
		 *
		 *  Wire interactive dom-elements (call when dom was updated/replaced f.e. by an ajax-injection)
		 *	@param: parent [object]
		 */
		wireChildren(parent)
		{
				this._wireChildren(parent);
		}

		/**!
		 *	Helper to calc scroll-animation durations
		 *	@param: h [int] -> height of the element to animate while visible in vieport
		 */
		getWindowHeight(h)
		{
				if (this._debug) console.log(`App::getWindowHeight w/ param: h = ${h}`);

				if (h) {
						return this.h + h;
				} else {
						return this.h;
				}
		}

		/**!
		 *	App::downloadCSV
		 *	The download function takes a CSV string, the filename and mimeType as parameters
		 *
		 *	@param: content [string] -> csv-string
		 *	@param: fileName [string]
		 *	@param: mimeType [string]
		 */
		downloadCSV(content, fileName, mimeType)
		{
				var
				a = this._doc[0].createElement('a');
				mimeType = mimeType || 'application/octet-stream';

				if (navigator.msSaveBlob)
				{
						// IE10
						navigator.msSaveBlob(new Blob([content],
								{
										type: mimeType
								}
						), fileName);

				} else if (URL && 'download' in a)
				{
						//html5 A[download]
						a.href = URL.createObjectURL(new Blob([content],
								{
										type: mimeType
								}
						));
						a.setAttribute('download', fileName);
						this.BODY[0].appendChild(a);
						a.click();
						this.BODY[0].removeChild(a);
				} else
				{
						location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
				}
		}
}

export default Base;

/**!
 * 	GMAP-UTILS
 *
 *  @name			   gmap-utils.js
 *  @desc			   Utils-Subpackage for Gmap Module (maps.googleapis.com).
 *
 *  @client			 WEIGELSTEIN
 *  @author 		 Ansgar Hiller <ansgar@weigelstein.de>
 *  @since			 03-2022
*/

import $ from 'jquery';

/* 	Location-Pins */
const LOCATION_PIN_WIDTH = 50;
const LOCATION_PIN_HEIGHT = 75;
const LOCATION_PIN_SCALE = 1;
const LOCATION_PIN_SPIKE_X_OFFSET = 0;
const LOCATION_PIN_FILE_NAME ="/img/route-pin.svg";

const ROUTE_PIN_WIDTH = 50;
const ROUTE_PIN_HEIGHT = 75;
const ROUTE_PIN_SCALE = 1;
const ROUTE_PIN_SPIKE_X_OFFSET = 0;
const ROUTE_PIN_FILE_NAME ="/img/route-pin.svg";

const ORIGIN_PIN_WIDTH = 50;
const ORIGIN_PIN_HEIGHT = 75;
const ORIGIN_PIN_SCALE = 1;
const ORIGIN_PIN_SPIKE_X_OFFSET = 0;
const ORIGIN_PIN_FILE_NAME ="/img/start-pin.png";

class GmapUtils
{
		/*
		*	@param {gmap|GmapBase}
		*/
		constructor(gmap, debug)
		{
				if (debug) console.log(`GmapUtils::constructor (js/ws/gmap/gmap-utils.js)`);
        this._debug   = debug;
				this._gmap 		= gmap;

				this._opt = {
						locationPinWidth: LOCATION_PIN_WIDTH,
						locationPinHeight: LOCATION_PIN_HEIGHT,
						locationPinScale: LOCATION_PIN_SCALE,
						locationPinSpikeXOffset: LOCATION_PIN_SPIKE_X_OFFSET,
						locationPinFileName: LOCATION_PIN_FILE_NAME,

            routePinWidth: ROUTE_PIN_WIDTH,
						routePinHeight: ROUTE_PIN_HEIGHT,
						routePinScale: ROUTE_PIN_SCALE,
						routePinSpikeXOffset: ROUTE_PIN_SPIKE_X_OFFSET,
						routePinFileName: ROUTE_PIN_FILE_NAME,

            originPinWidth: ORIGIN_PIN_WIDTH,
						originPinHeight: ORIGIN_PIN_HEIGHT,
						originPinScale: ORIGIN_PIN_SCALE,
						originPinSpikeXOffset: ORIGIN_PIN_SPIKE_X_OFFSET,
						originPinFileName: ORIGIN_PIN_FILE_NAME,
				}
				$.extend(this._opt, this.options);

        this.options = this._opt;

        // Services
				this.Geocoder = new this.api.Geocoder();
				this.DirectionsService = new this.api.DirectionsService();
				this.PlacesService = new this.api.places.PlacesService(this.map);

				// Custom events
				this._events = $.Callbacks();
		}

		// PRIVATE METHODS

		/**!
     *  _dispatch()
     *
     *  -> Dispatch custom events
     *  @param: fn [function]
     *  @param: params [object]
     *  @param: string [string]
     */
		_dispatch(fn, params, string)
		{
        if (this._debug) console.log(`GmapUI::_dispatch (Custom-Event-Dispatcher)`);

				if ( typeof fn === 'function' ) {
						this._events
								.add(fn)
								.fire(params, string, this)
								.remove(fn);
				}
		}

		// READ-ONLY

		// Gmap Instance
		get g() { return this._gmap; }

    // GmapUI
    get ui() { return this.g.ui; }

		// Element
		get element() { return this.g.element; }

		// Map
		get map() { return this.g.map; }

		// Api
		get api() { return this.g.api; }

    // Options
		get opt() { return this.g.options; }
    set opt(opt) { this.g.options = opt; }

    // Options (same as 'set opt()')
		get options() { return this.g.options; }
		set options(opt) { this.g.options = opt; }

    // Region (like f.e. 'Germany' or 'Europe, Continent')
    get region() { return this.g.region; }
    set region(region) { this.g.region = region; }

		// Template (Joomla template name)
		get template() { return this.opt.template; }

    // Inherited Properties

    // DirectionsDisplay
		get directionsDisplay() { return this.ui.DirectionsDisplay; }

    // Route-Markers
    get routeMarkers() { return this.g.routeMarkers; }
    set routeMarkers(markers) { this.g.routeMarkers = markers; }

    // MarkerInfoWindow
		get markerInfoWindow() { return this.g.markerInfoWindow; }
		set markerInfoWindow(iw) { this.g.markerInfoWindow = iw; }

    // RouteInfoWindow
		get routeInfoWindow() { return this.g.routeInfoWindow; }
		set routeInfoWindow(iw) { this.g.routeInfoWindow = iw; }

    // OriginInfoWindow
		get originInfoWindow() { return this.g.originInfoWindow; }
		set originInfoWindow(iw) { this.g.originInfoWindow = iw; }

    // Native properties

    // Geocoder
		get geocoder() { return this.Geocoder; }

    // DirectionsService
		get directionsService() { return this.DirectionsService; }

    // PlacesService
		get placesService() { return this.PlacesService; }

		// Location-Pin
		get pin()
		{
				var
				_mw = this.opt.locationPinWidth * this.opt.locationPinScale,
				_mh = this.opt.locationPinHeight * this.opt.locationPinScale;
				return {
									 url: '/templates/' + this.template + this.opt.locationPinFileName,
						scaledSize: new this.api.Size(_mw,_mh),
									size: new this.api.Size(this.opt.locationPinWidth, this.opt.locationPinHeight),
								origin: new this.api.Point(0,0),
								anchor: new this.api.Point((_mw * .5) + this.opt.locationPinSpikeXOffset, _mh)
				};
		}

		// Route-Pin
		get routePin()
		{
				var
        _mw = this.opt.routePinWidth * this.opt.routePinScale,
				_mh = this.opt.routePinHeight * this.opt.routePinScale;
				return {
									 url: '/templates/' + this.template + this.opt.routePinFileName,
						scaledSize: new this.api.Size(_mw,_mh),
									size: new this.api.Size(this.opt.routePinWidth, this.opt.routePinHeight),
								origin: new this.api.Point(0,0),
								anchor: new this.api.Point((_mw * .5), _mh)
				};
		}

		// Start-Pin (originPlaceId)
		get originPin()
		{
				var
        _mw = this.opt.originPinWidth * this.opt.originPinScale,
				_mh = this.opt.originPinHeight * this.opt.originPinScale;
				return {
									 url: '/templates/' + this.template + this.opt.originPinFileName,
						scaledSize: new this.api.Size(_mw,_mh),
									size: new this.api.Size(this.opt.originPinWidth, this.opt.originPinHeight),
								origin: new this.api.Point(0,0),
								anchor: new this.api.Point((_mw * .5), _mh)
				};
		}

		// PUBLIC METHODS

    getPinIcon(type)
    {
        switch(type) {
            case 'division':
                return this.pin;
            break;
            case 'partner':
                return this.routePin;
            break;
            default:
                return this.pin;
            break;
        }
    }

    // Create-Marker
		createMarker(options = {}, onResolved = null)
    {
        if (this._debug) console.log(`GmapUtils::createMarker w/ options = { position: { lat: ${options.position.lat}, lng: ${options.position.lng} }, ...}`);
        var _opt = {
            type: 'destination',
            map: this.map,
            icon: this.pin,
            animation: this.api.Animation.DROP,
            position: options.position || this.g.center,
            title: null,
						descr: null,
            siteNotice: null,
            onInfoWindowClose: null
        };
        $.extend(_opt, options);

        var
        _this = this,
        _marker = new this.api.Marker(_opt);
        _this._dispatch(onResolved, _marker, null);

        return _marker;
    }

    // Create-Marker-Info-Window
		createMarkerInfoWindow(marker, onClose = null)
    {
        if (this._debug) console.log(`GmapUtils::createMarkerInfoWindow w/ marker.id = ${marker.id}`);

        let
				_this = this,
        _title = (!!marker.title) ? marker.title : marker.name,
				_iw = new this.api.InfoWindow(),
        _descr = marker.descr;
        if (marker.hasOwnProperty('contact'))
        {
            _descr += '<br><br>';
            if (marker.contact.telephone !== '') {
                _descr += '<span class="icon-old-phone small me-2"></span> ';
                _descr += '<a href="tel:' + marker.contact.telephone + '" target="_blank">' + marker.contact.telephone + '</a><br>';
            }
            if (marker.contact.mobile !== '') {
                _descr += '<span class="icon-mobile small me-2"></span> ';
                _descr += '<a href="tel:' + marker.contact.mobile + '" target="_blank">' + marker.contact.mobile + '</a><br>';
            }
            if (marker.contact.fax !== '') {
                _descr += '<span class="icon-print small me-2"></span> ';
                _descr += marker.contact.fax + '<br>';
            }
            if (marker.contact.email !== '') {
                _descr += '<span class="icon-mail small me-2"></span> ';
                _descr += '<a href="mailto:' + marker.contact.email + '" target="_blank">' + marker.contact.email + '</a><br>';
            }
            if (marker.contact.webpage !== '') {
                _descr += '<span class="icon-globe small me-2"></span> ';
                _descr += '<a href="' + marker.contact.webpage + '" target="_blank">' + marker.contact.webpage.replace('https://','') + '</a><br>';
            }
            _descr += '<br>';
            _descr += '<span class="icon-location small me-2"></span> ';
            _descr += '<a href="' + this.g.directionsUrl + '" target="_blank">Get directions';
            _descr += '<span class="icon-export small ms-1"></span></a>';
        }

        var _content = _this.createInfoWindowContentString(_descr, _title, marker.siteNotice);

        _iw.setContent(_content);
        _iw.setOptions({
            minWidth: 200,
            pixelOffset: new this.api.Size(0, -20)
        });
        _iw.addListener('closeclick', function() {
						_this._dispatch(onClose, _iw, null);
        });

        return _iw;
    }

    // Create-Info-Window-Content-String
		createInfoWindowContentString(
				body = null,
				title = null,
				siteNotice = null
		){
        if (this._debug) console.log(`GmapUtils::createInfoWindowContentString w/ body = ${body}, title = ${title} and siteNotice = ${siteNotice}`);

				if (!!body === false && !!title === false && !!siteNotice === false) return null;

        var
        			bodyString = '<div class="info-window-body-content">' + body + '</div>',
        siteNoticeString = (!!siteNotice) ? '<div class="info-window-site-notice border-bottom mb-3">' + siteNotice + '</div>' : '',
        		 titleString = (!!title) 			? '<div class="info-window-title px-0 mb-1"><strong>' + title + '</strong></div>' : '';

        return '<div id="iw-content" class="info-window-content pb-1 px-2">' + siteNoticeString + titleString + bodyString + '</div>';
    }

    // USER-GEOLOCATION

    // Get-User-Geolocation
		getUserGeolocation(options = {})
		{
				if (this._debug) console.log(`GmapUtils::getUserGeolocation`);

        let
        _this = this,
        _opt = {
            onResolve: null,
             onReject: null
        }
        $.extend(_opt, options);

				// Try HTML5 GEOLOCATION
				if(navigator.geolocation)
				{
						navigator.geolocation.getCurrentPosition(
                function(position)
                {
                    var _location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    _this._dispatch(_opt.onResolve, _location, 'OK');
						    },
                function(error)
                {
								    _this._handleNoGeolocation(true, error);
                    _this._dispatch(_opt.onReject, error, 'FAIL');
						    }
            );
				} else {
						// Browser doesn't support Geolocation
						_this._handleNoGeolocation(false);
            _this._dispatch(_opt.onReject, { code: 0, message: 'Your browser doesn\'t support geolocation.' }, 'FAIL');
				}
		}

    // Get-Place-Details
    getPlaceDetails(id, options = {})
		{
				if (this._debug) console.log(`GmapUtils::getPlaceDetails w/ id = ${id}`);

        var
        _this = this,
        _opt = {
            onResolve: null,
             onReject: null
        }
        $.extend(_opt, options);

				if (id !== undefined)
				{
						this.PlacesService.getDetails({placeId: id}, function(place, status)
						{
								if (status === _this.api.places.PlacesServiceStatus.OK)
								{
                    _this._dispatch(_opt.onResolve, place, status);
								} else {
                    _this._dispatch(_opt.onReject, null, status);
                }
						});
				}
		}

    getAddressComponentFromLocation(location, componentType, nameType = 'long_name')
    {
        if (this._debug) console.log(`GmapUtils::getAddressComponentFromLocation`);

        if (!location.hasOwnProperty('address_components')) return null;

        var _comps = location.address_components;
        for(var key in _comps)
        {
            if (_comps[key]['types'].includes(componentType))
            {
                return _comps[key][nameType];
            }
        }

        return null;
    }

    resolveLocationsByAddresses(input, options = {})
    {
        if (this._debug) console.log(`GmapUtils::resolveLocationsByAddresses`);
        if (typeof input !== 'object') return false;

        var
        _this = this,
        _length = input.length,
        _locations = [],
        _opt = {
            onResolve: null,
        };
        $.extend(_opt, options);

        var output = [];

        input.forEach((address, i) => {
            this.Geocoder.geocode(
                {
                    'address': address
                },
                function(results, status)
                {
                    var data = {
                        status: status,
                        title: address.split(',')[0],
                        id: (output.length + 1),
                    };
                    if (status === _this.api.GeocoderStatus.OK)
                    {
                        if (!!results[0])
                        {
                            data.location = results[0];
                        }
                    }

                    output.push(data);

                    if (output.length >= _length)
                    {
                        _this._dispatch(_opt.onResolve, output, status);
                    }
                }
            );
        });
    }

    resolveLocationsByContacts(categories, options = {})
    {
        if (this._debug) console.log(`GmapUtils::resolveLocationsByContacts`);
        if (typeof categories !== 'object') return false;

        var
        _this = this,
        _locations = [],
        _output = [],
        _opt = {
            onResolve: null,
        };
        $.extend(_opt, options);
        for (const [key, category] of Object.entries(categories))
        {
            if (typeof category === 'object') {
                for (const [key, contact] of Object.entries(category))
                {
                    _locations.push(contact);
                }
            }
        }

        var _length = _locations.length;

        _locations.forEach((contact, i) => {
            this.Geocoder.geocode(
                {
                    'address': contact.address
                },
                function(results, status)
                {
                    var data = {
                        status: status,
                        title: contact.name,
                        id: contact.id,
                        contact: contact,
                    };
                    if (status === _this.api.GeocoderStatus.OK)
                    {
                        if (!!results[0])
                        {
                            data.location = results[0];
                        }
                    }

                    _output.push(data);

                    if (_output.length >= _length)
                    {
                        _this._dispatch(_opt.onResolve, _output, status);
                    }
                }
            );
        });
    }

    // DISPLAY ROUTE

		calculateAndDisplayRoute(originPlaceId, destinationPlaceId)
		{
				if (this._debug) console.log(`GmapUtils::calculateAndDisplayRoute w/ originPlaceId = ${originPlaceId} and destinationPlaceId = ${destinationPlaceId}`);

				var _this = this;

        for(var key in this.routeMarkers)
        {
            this.routeMarkers[key].setMap(null);
        }

				// Retrieve the start and end locations and create a DirectionsRequest.
				this.directionsService.route(
            {
						    origin: { 'placeId': originPlaceId },
						    destination: { 'placeId': destinationPlaceId },
                travelMode: this.ui.travelMode,
                provideRouteAlternatives: true
				    },
            function(response, status)
						{

								// Route the directions and pass the response to a function to create
								// markers for each step.
								if (status === _this.api.DirectionsStatus.OK)
								{
										_this.routeMarkers = [];

                    if (_this._debug) console.log(response.routes[0].legs);

										// if (_this._pacInput) _this._pacInput.val(response.routes[0].legs[0].start_address);

										_this.directionsDisplay.setMap(_this.map);
										_this.directionsDisplay.addListener('directions_changed', () =>
										{
												if (_this._debug) console.log(_this.directionsDisplay.getDirections());
												if (_this._debug) console.log(_this.directionsDisplay.getPanel());
										});
										_this.directionsDisplay.setDirections(response);
										_this.g.routeInfoWindow = (_this.g.routeInfoWindow) ? _this.g.routeInfoWindow : new _this.api.InfoWindow();
										_this.showSteps(response);
								} else {
										window.alert('Directions request failed due to ' + status);
								}
						}
				);
		}

    clearRoute()
    {
        if (this._debug) console.log(`GmapUtils::clearRoute`);
        this.ui.origin = false;
    }

		showSteps(directionResult)
		{
				if (this._debug) console.log(`GmapUtils::showSteps`);

				// For each step, place a marker, and add the text to the marker's infowindow.
				// Also attach the marker to an array so we can keep track of it and remove it
				// when calculating new routes.
				var _myRoute = directionResult.routes[0].legs[0];

				for (var i = 0; i < _myRoute.steps.length; i++)
				{
						this.routeMarkers[i] = new this.api.Marker({ icon: this.routePin });
						var marker = this.routeMarkers[i];
						marker.setMap(this.map);
						marker.setPosition(_myRoute.steps[i].start_location);
						this.attachInstructionText(this.g.routeInfoWindow, marker, this.createInfoWindowContentString(_myRoute.steps[i].instructions, 'Step ' + (i + 1)));
				}
		}

		attachInstructionText(stepDisplay, marker, text, map)
		{
				if (this._debug) console.log('GmapUtils::attachInstructionText');

				this.api.event.addListener(marker, 'click', () =>
				{
						// Open an info window when the marker is clicked on, containing the text
						// of the step.
						stepDisplay.setContent(text);
						stepDisplay.open(map, marker);
				});
		}

    // PRIVATE METHODS

    // No User Geolocation Handler
    _handleNoGeolocation(errorFlag, error = {})
		{
				if (this._debug) console.log('GmapUI._handleNoGeolocation: ' + errorFlag);

				var content;

				if (errorFlag)
				{
						content = 'The Geolocation service failed: ' + error.message;
				} else {
						content = 'Your browser doesn\'t support geolocation.';
				}

				this.g.activeMarker = this.g.defaultMarker;

        alert(content);
		}
}

export default GmapUtils;

/**!
 * 	GMAP-BASE
 *
 *  @name			   gmap-base.js
 *  @desc			   module w/ google-maps-api features (maps.googleapis.com).
 *
 *  @client			 WEIGELSTEIN
 *  @author 		 Ansgar Hiller <ansgar@weigelstein.de>
 *  @since			 01-2017
 *  @update			 03-2022
*/

import $ from 'jquery';
import { gsap } from 'gsap';
import { Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Roadmap from './mapstyles/roadmap';
import GmapUI from './gmap-ui';
import GmapUtils from './gmap-utils';
import Utils from './../utils';
gsap.registerPlugin(ScrollTrigger);
// const LoadGoogleMapsApi = require('load-google-maps-api');

import { Loader } from "@googlemaps/js-api-loader";

/* 	Api-Key
(https://console.cloud.google.com/apis/dashboard) */
const API_KEY_DEFAULT = 'AIzaSyA5xCOioHBaWH7V2PMJknc1kMoZAK3UOB8';

/* 	Default-Center
(Home of Weigelstein - Hospeltstr. 69, 50825 Cologne) */
const DEFAULT_CENTER = { lat: 50.95218201705619, lng: 6.902873918523839 };

/* 	Default-Zoom
Default initial zoom value */
const DEFAULT_ZOOM = 15;
const DEFAULT_ZOOM_MIN = 3;
const DEFAULT_ZOOM_MAX = 20;
const DIRECTIONS_BASE_URL = 'https://www.google.com/maps/dir/?api=1';

/* Tile-Size
(For mercator-projection calculation) */
const TILE_SIZE = 256;

class GmapBase
{
		_loadApi(api_key = null)
		{
        const loader = new Loader({
            apiKey: api_key,
            libraries: ['places'],
            language: 'de',
            region: 'DE',
            v: 'quarterly'
        });

        let _this = this;

        loader.load().then(async () => {
            const { Map } = await google.maps.importLibrary("maps");
            const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

            _this._api = google.maps;
            _this._createMap();
        });
		}

		constructor(element, debug = false)
		{
				if (debug) console.log('GmapBase::constructor (js/ws/gmap/gmap-base.js)');

        this._debug = debug;
        this._element = element;

        let api_key = this._element.data('api-key') || null;

				if (null === api_key) api_key = API_KEY_DEFAULT;

				this._canvas = document.getElementById('WsMapsMapCanvas'); // canvas element
				this._map = null;
				this._opt = {
							 mapid: 1,
								zoom: DEFAULT_ZOOM,
             zoomMin: DEFAULT_ZOOM_MIN,
             zoomMax: DEFAULT_ZOOM_MAX,
								 lat: DEFAULT_CENTER.lat,
						 		 lng: DEFAULT_CENTER.lng,
							 title: null, // Info-Window-Title
							 descr: null, // Info-Window-Description
						template: null, // Joomla-Template-Name
						nativeUi: false,
					 useCenter: false,
         useContacts: false,
           locations: [],
         markerDelay: 200,
				}
				$.extend(this._opt, this._element.data());

				this._center = this.defaultCenter;

				// Properties;
        this._markers = [];
        this.__markers = []; // the unfiltered set of _markers
        this._locations = [];
        this._routeMarkers = [];
				this._markerInfoWindow = null;
        this._routeInfoWindow = null;
        this._defaultMarker = null;
        this._activeMarker = null;
        this._userLocationMarker = null;
        this._smoother = false;
        this._region = null;

        // Events
        this._onActiveMarkerChangedEvents = $.Callbacks();
        this._onRegionChangedEvents = $.Callbacks();
        // Custom events
				this._events = $.Callbacks();

        // Map
        this._loadApi(api_key);
		}

    _createMap()
		{
        if (this._debug) console.log(`GmapBase::_createMap`);

        this._maptype = (this.options.mapTypeStyle === 'default') ?
            Roadmap.getStyledMapType(this._api, this.options.theme) :
            Roadmap.getStyledMapType(this._api, this.options.mapTypeStyle);

        let _options = {
            zoom: this.options.zoom,
            center: this.center,
            disableDefaultUI: !(!!parseInt(this.options.nativeUi)),
            draggable: true,
            scrollwheel: false,
            gestureHandling: 'cooperative',
            panControl: true,
            mapTypeControlOptions: {
                mapTypeIds: [this._api.MapTypeId.ROADMAP, this._maptype.name]
            }
        }

	    	this.map = new this._api.Map(this._canvas, _options);
        this.map.mapTypes.set(this._maptype.name, this._maptype);
        this.map.setMapTypeId(this._maptype.name);

        // UI
				this._ui = new GmapUI(this, this._debug);

				// Utils
				this._utils = new GmapUtils(this, this._debug);

        this.loadLocations();
	  }

    loadLocations()
    {
        let
        token = Joomla.getOptions('csrf.token', ''), // in case the filter-form is csfr protected
        data = JSON.parse('{"'+ token + '":"1" }'),
        url = '/index.php'
            + '?format=json'
            + '&option=com_ajax'
            + '&template=' + this.utils.template
            + '&task=getlocations'
            + '&module-id=' + this.options.moduleId
            + '&ignoreMessages=0',
        options = {
                 url: url,
                type: 'post',
               async: true,
             context: this,
            dataType: 'json'
        };

        // and add data to ajax-options
        options.data = data;

        $.ajax(options)
        .done(function(response)
        {
            if (this._debug) console.log(`GmapBase::_loadLocations $.ajax -> done`);
            let _this = this;

            if (!this.options.useContacts)
            {
                if (this.options.useCenter)
                {
                    this.center = this.defaultCenter;
                    this.init();
                } else {
                    this.utils.resolveLocationsByAddresses(JSON.parse(response.data), {
                        onResolve: function(data, status)
                        {
                            _this.locations = data;
                            _this.init();
                        }
                    });
                }
            } else {
                this.utils.resolveLocationsByContacts(JSON.parse(response.data), {
                    onResolve: function(data, status)
                    {
                        // console.log(data);
                        _this.locations = data;
                        _this.init();
                    }
                });
            }
        })
        .fail(  () => { if (this._debug) console.log(`GmapBase::_loadLocations $.ajax -> fail`); })
        .always(() => { if (this._debug) console.log(`GmapBase::_loadLocations $.ajax -> always`); });
    }

    init()
    {
        if (this._debug) console.log(`GmapBase::init`);

        var _this = this;

        if (this.options.useCenter)
        {
            this.center = this.defaultCenter;
        }

        if (!this.locations.length)
        {
            if (!this.locations.length)
            {   // set default destination to defaultCenter
                this.ui.setDefaultDestinationByPosition(this.center);

                gsap.to( this._canvas, 1,
                    {
                        alpha: 1,
                        ease: Power1.easeOut
                    }
                );

                return;
            }
        }

        gsap.to( this._canvas, 1,
            {
                alpha: 1,
                scrollTrigger: {
                    trigger: this._canvas,
                    start: "to bottom"
                },
                ease: Power1.easeOut,
                onComplete: function() {
                    var _hasActiveMarker = _this.options.useCenter;
                    _this.locations.forEach((item, i) => {
                        setTimeout(function()
                        {
                            var
                            _options = {
                                position: {
                                    'lat': item.location.geometry.location.lat(),
                                    'lng': item.location.geometry.location.lng()
                                },
                                icon: _this.utils.getPinIcon(),
                                id: item.id,
                                title: item.title,
                                descr: item.location.formatted_address,
                                siteNotice: item.siteNotice || _this.options.siteNotice,
                                location: item.location,
                                onInfoWindowClose: function() {
                                    // _this.region = null;
                                    _this.activeMarker = null;
                                }
                            },
                            _contact = item.contact || null;
                            if (!!_contact) {
                                _options.contact = _contact;
                                _options.siteNotice = _contact.sitenotice;
                                _options.icon = _this.utils.getPinIcon(_contact.pintype);
                            }
                            var _marker = _this.utils.createMarker(_options);

                            _marker.addListener('click', function()
                            {
                                _this.activeMarker = this;
                            });

                            _this.addMarker(_marker);
                            if (_this._debug) console.log(`Marker ID: ${_marker.id}`);

                            // Active marker
                            if (!_hasActiveMarker)
                            {
                                if (_this._debug) console.log(`Active Marker ID: ${_marker.id}`);
                                _this.defaultMarker = _marker;
                                _this.activeMarker = _this.defaultMarker;

                                if (!_this.options.useCenter)
                                {
                                    _this.defaultCenter = {
                                        lat: _marker.location.geometry.location.lat(),
                                        lng: _marker.location.geometry.location.lng()
                                    };
                                    _this.center = _this.defaultCenter;
                                }
                                _hasActiveMarker = true;
                            }
                        }, i * _this.options.markerDelay);
                    });
                }
            }
        );

        $(window).trigger('resize');
    }

    /**!
		 * 	App::on
		 *
		 *	Add callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		on(event, fn)
		{
        if (this._debug) console.log(`GmapBase::on w/ event = ${event}`);
				if (typeof fn === 'function')
				{
						switch(event) {
								case 'activeMarkerChanged':
										this._onActiveMarkerChangedEvents.add(fn);
										break;
								case 'regionChanged':
										this._onRegionChangedEvents.add(fn);
										break;
						}
				}
		}

		/**!
		 * 	App::off
		 *
		 *	Remove callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		off(event, fn)
		{
        if (this._debug) console.log(`GmapBase::off w/ event = ${event}`);
				if (typeof fn === 'function')
				{
            switch(event) {
                case 'activeMarkerChanged':
                    this._onActiveMarkerChangedEvents.remove(fn);
                    break;
                case 'regionChanged':
                    this._onRegionChangedEvents.remove(fn);
                    break;
            }
				}
		}

		// READ-ONLY
		// Element
		get element() { return this._element; }

    // Canvas
		get canvas()  { return this._canvas; }

    // Smoother (ScrollSmoother Instance @see: https://greensock.com/docs/v3/Plugins/ScrollSmoother)
		get smoother() {
        if (!this._smoother && typeof FX === 'object')
        {
            // Get the current ScrollSmoother instance
            this._smoother = FX.smoother;
        }

        return this._smoother;
    }

		// GoogleMapsApi
		get api() { return this._api; }

		// UI (GmapUI)
		get ui() { return this._ui; }

		// Utils (GmapUtils)
		get utils() { return this._utils; }

    get directionsUrl()
    {
        var
        _originStr = '';

        if (null !== this.ui.origin && typeof this.ui.origin.lat === 'function')
        {
            _originStr = '&origin=' + this.ui.origin.lat() + ',' + this.ui.origin.lng();
        }
        if (null !== this.ui.origin && typeof this.ui.origin.lat === 'number')
        {
            _originStr = '&origin=' + this.ui.origin.lat + ',' + this.ui.origin.lng;
        }

        if (this._debug) console.log(`GmapBase get directionsURL w/ _originStr = ${_originStr}`);

        return DIRECTIONS_BASE_URL
            + '&destination=' + this.ui.destination.lat + ',' + this.ui.destination.lng
            + '&destination_place_id=' + this.ui.destinationPlaceId
            + _originStr
            + '&travelmode=' + this.ui.travelMode.toLowerCase()
            + '&dir_action=navigate';
    }

		// GETTER & SETTER
		// Options
		get options() { return this._opt; }
		set options(opt) {
				if (typeof opt === 'object') $.extend(this._opt, opt);
		}

		// Map
		get map() { return this._map; }
    set map(map) { this._map = map; }

		// Zoom
		get zoom() { return this.map.getZoom() || this.options.zoom; }
    set zoom(zoom) {
        this.map.setZoom(zoom);
    }

		// (Current) Center
    get center() { return this._center; }
    set center(center) {
				if (typeof center === 'object')
				{
        		this._center = center;
        		if (null !== this.map) this.map.panTo(this._center);
				}
    }

		// Default Center
    get defaultCenter() {
        return {
            lat: this.options.lat,
            lng: this.options.lng
        };
    }
    set defaultCenter(center) {
				if (typeof center === 'object')
				{
						this.options.lat = center.lat;
						this.options.lng = center.lng;
				}
    }

    // Default-Marker
		get defaultMarker() { return this._defaultMarker; }
    set defaultMarker(marker) {
				if (this._debug) console.log(`GmapBase set defaultMarker`);
				if (!!this._defaultMarker) this._defaultMarker.setMap(null);
				this._defaultMarker = marker;
        this.addMarker(marker);
		}

		// Active-Marker
		get activeMarker() { return this._activeMarker; }
    set activeMarker(marker) {
				if (this._debug) console.log(`GmapBase set activeMarker`);
        this._activeMarker = marker;
        if (null !== marker)
        {
            var _this = this;
            this.ui.destination = marker.location;
            this.markerInfoWindow = _this.utils.createMarkerInfoWindow(marker, function(iw) {
                _this.center = _this.defaultCenter;
                _this.zoom = _this.options.zoom;
                _this.markerInfoWindow.close();
                _this.activeMarker = null;
            });
            this.markerInfoWindow.open(this.map, this._activeMarker);
            // this.region = this.utils.getAddressComponentFromLocation(marker.location, 'country');
            // console.log(this.region);
        } else {
            // this.region = null;
            this.markerInfoWindow.close();
        }
        this._onActiveMarkerChangedEvents.fire(this._activeMarker);
		}

    // User-Location-Marker
		get userLocationMarker() { return this._userLocationMarker; }
    set userLocationMarker(marker) {
				if (this._debug) console.log(`GmapBase set userLocationMarker`);
				if (!!this._userLocationMarker) this._userLocationMarker.setMap(null);
				this._userLocationMarker = marker;
		}

    // Markers
    get markers() { return this._markers; }
    set markers(markers) {
        if (this._debug) console.log(`GmapBase set markers (${markers.length} markers)`);
        if (this._markers.length)
        {
            for(let key in this._markers)
            {
                this._markers[key].setMap(null);
            }
        }
        this._markers = markers;
        for(var key in this._markers)
        {
            this._markers[key].setMap(this.map);
        }
    }
    addMarker(marker) {
        if (this._debug) console.log(`GmapBase::addMarker w/ id = ${marker.id}`);
        if (null !== marker && !this._markers.includes(marker))
        {
            marker.setMap(this.map);
            this._markers[marker.id] = marker;

            if (!this.__markers.includes(marker))
            {
                // add to the set of unfiltered markers (all markers ever added on runtime).
                this.__markers[marker.id] = marker;
            }
        }
    }
    removeMarker(marker) {
        if (this._debug) console.log(`GmapBase::removeMarker w/ id = ${marker.id}`);
        if (this._markers.includes(marker))
        {
            marker.setMap(null);
            this._markers = Utils.arrayRemove(this._markers, marker);
        }
    }

    /*
     *  Filter all markers by @prop == @value
     *  @prop accepts a dot-chained string: f.e. @prop = 'geometry.location.lat'
     *  resolves to the value of @marker['geometry']['location']['lat'].
     *
     *  @value [mixed]
     *  @prop [string|null]
     */
    filterMarkers(value, prop = null) {
        if (this._debug) console.log(`GmapBase::filterMarkers w/ prop = ${prop} and value = ${value}`);
        if (null !== value && null !== prop)
        {
            this.markers = this.__markers.filter(function(marker)
            {
                let
                _props = '',
                _prop = prop.split('.');
                if (!marker.hasOwnProperty(_prop[0])) return false;
                for(let key in prop)
                {
                    if (eval('marker' + _props).hasOwnProperty(_prop[key]))
                    {
                        _props += '["' + _prop[key]+ '"]';
                    }
                }
                return (eval('marker' + _props) == value);
            });
        } else {
            this.markers = this.__markers;
        }

        return this.markers;
    }

    // Locations
    get locations() { return this._locations; }
    set locations(locations) {
        if (this._debug) console.log(`GmapBase set locations (${locations.length} locations)`);
        this._locations = locations;
    }
    addLocation(location) {
        if (this._debug) console.log(`GmapBase::addLocation ${location}`);
        var
        _marker = null,
        _this = this;
        if (!this._locations.includes(location))
        {
            this._locations.push(location);
            _marker = this.utils.createMarker(location);
            _marker.addListener('click', function() {
                _this.activeMarker = this;
            });
            this.addMarker(_marker);
        }

        return _marker;
    }

    // Route-Markers
    get routeMarkers() { return this._routeMarkers; }
    set routeMarkers(markers) {
        if (this._debug) console.log(`GmapBase set routeMarkers (${markers.length} routeMarkers)`);
        if (this._routeMarkers.length)
        {
            for(var key in this._routeMarkers)
            {
                this._routeMarkers[key].setMap(null);
            }
        }
        this._routeMarkers = markers;
    }

    // MarkerInfoWindow
		get markerInfoWindow() { return this._markerInfoWindow; }
		set markerInfoWindow(iw)
		{
        if (this._debug) console.log(`GmapBase set markerInfoWindow`);

				if (this._markerInfoWindow) this._markerInfoWindow.close();
				this._markerInfoWindow = iw;
		}

    // RouteInfoWindow
		get routeInfoWindow() { return this._routeInfoWindow; }
		set routeInfoWindow(iw)
		{
        if (this._debug) console.log(`GmapBase set routeInfoWindow`);

				if (this._routeInfoWindow) this._routeInfoWindow.close();
				this._routeInfoWindow = iw;
		}

    // OriginInfoWindow
		get originInfoWindow() { return this._originInfoWindow; }
		set originInfoWindow(iw)
		{
        if (this._debug) console.log(`GmapBase set originInfoWindow`);

				if (this._originInfoWindow) this._originInfoWindow.close();
				this._originInfoWindow = iw;
		}

    // Region (like f.e. 'Germany' or 'Europe, Continent')
    get region() { return this._region; }
    set region($region)
    {
        if (this._debug) console.log(`GmapBase set region = ${$region}`);
        this._region = $region;

        if (null !== this._region)
        {
            var _this = this;

            this.utils.Geocoder.geocode(
                {
                    'address': this._region
                },
                function(results, status)
                {
                    if (status === _this.api.GeocoderStatus.OK)
                    {
                        if (results[0])
                        {
                            var data = results[0];
                            _this.map.fitBounds(data.geometry.viewport);
                        }
                    } else {
                        _this._region = null;
                    }
                }
            );
        } else {
            this.zoom = this.options.zoom;
            this.center = this.defaultCenter;
        }
        this._onRegionChangedEvents.fire(this._region);
    }
}

export default GmapBase;

/**!
 *  FX
 *
 *  @name			      fx.js (js/ws/fx.js)
 *
 *  @package       JOOMLA BASE THEME
 *  @description	 Main frontend application w/ UX Effects
 *  @copyright 	   (c) 2023 Ansgar Hiller <ansgar@weigelstein.de>
*/

import $ from 'jquery';
import { gsap } from 'gsap';
import { Power1, Power2, Linear } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);
import Utils from './utils';

class FX
{
    get trigger()         { return $('[data-scroll-target]'); }
    get smootherContent() { return $('#smooth-content'); }
    get HEADER() 	        { return $('#header') 	|| []; }
		get HERO() 		        { return $('#hero') 		|| []; }
    get scrollTriggers()  { return ScrollTrigger.getAll(); }
    get smoother()        { return this._smoother; }

    constructor(debug = false)
    {
        if (debug) console.log(`FX::constructor (js/ws/fx.js)`);
        this._debug = debug;
        this._smoother = false;
        this._smootherTimer = null;
        this._scrollTrigger = null;
        this._headerTween = null;
        this._hash = window.location.hash;

        let _this = this;

        // ScrollTo Trigger (defined by `data-scroll-target` attr. which accepts element-selectors [string] or a position in px [int]).
        if (!!this.trigger.length)
        {
            if (this._debug) console.log(`FX: ${this.trigger.length} ScrollTo Trigger encountered`);

            this.trigger.on(
                {
                    'click': _this.__handleTrigger.bind(_this),
                }
            );
        }

        // Activate smooth scrolling (@see: https://greensock.com/docs/v3/Plugins/ScrollSmoother)
        // only on pages without scrollable containers
        if (this.smootherContent.length && !APP.isiOS && !$('iframe, .js-youtube, .table-responsive').length)
        {
            if (this._debug) console.log(`FX: ScrollSmoother active`);

            this._smootherTimer = setTimeout(function()
            {
                _this._smoother = ScrollSmoother.create(
                    {
                        smooth: .65,              // how long (in seconds) it takes to "catch up" to the native scroll position
                        effects: true,            // looks for data-speed and data-lag attributes on elements
                        ignoreMobileResize: true, // vertical resizes (of 25% of the viewport height) on touch-only devices won't trigger a
                                                  // ScrollTrigger.refresh(), avoiding the jumps that can happen when the start/end values
                                                  // are recalculated
                        normalizeScroll: true,
                    }
                );

                // Scroll to hash (#) if encountered in url (www.my-site.de/somewhere#[hash])
								if (_this._hash !== '')
                {
										Utils.refineURL(); // removes hash and GET-params from the url
                    _this._smoother.scrollTo(_this._hash, true);
                }
                _this.__init();
            }, 250);
        } else
        {
            this._smoother = false;
            _this.__init();
        }

        if (!this._smoother && _this._hash !== '')
        {
            $(window).scrollTop(_this._hash);
        }
    }

    __init()
    {
        if (this._debug) console.log(`FX::__init (js/ws/fx.js)`);

        // Show/hide HEADER based on scroll-direction
        // (@see: https://greensock.com/forums/topic/26852-hide-header-on-scroll/).
        if (APP.HEADER.length && !APP.useSmallScreenBehavior)
        {
            this._scrollTrigger = ScrollTrigger.create(
                {
                    start: APP.headerHeight,
                    end: 99999,
                    onUpdate: ({progress, direction, isActive}) => {
                        if (this._debug) console.log(`FX::scrollTrigger => scroll-dirction: ${direction}, isActive: ${isActive}`);

                        if (direction === -1 && isActive === false)
                        {
                            APP.PAGE.removeClass('shrink-header');
                        }
                        else if (direction === 1 && isActive === true)
                        {
                            APP.PAGE.addClass('shrink-header');
                        }
                    }
                }
            );
        }

        // Pin toc (table-of-content) elements in sidebar column
        let _toc = $('#toc-pin');

        if (_toc.length)
        {
            let
            _col    = _toc.closest('.sidebar-col'),
            _start  = 'top ' + (APP.headerHeight + 20) + 'px',
            _end    = 'bottom bottom-=' + (APP.h - ((APP.headerHeight + 20) + _toc.height())) + 'px';

            ScrollTrigger.matchMedia({
                '(min-width: 992px)': function () {
                    let _st = ScrollTrigger.create({
                        trigger: _col,
                        pin: _toc,
                        start: _start,
                        end: _end
                    });
                }
            });
        }

        // Add more fx here
    }

    /*
    *   HANDLE-TRIGGER
    *   Handle clicks on scroll-trigger-buttons. (defined by `data-scroll-target` attr.
    *   which accepts element-selectors [string] or a position in px [int])
    */
    __handleTrigger(e)
    {
        e.preventDefault();

        if (this._debug) console.log(`FX::__handleTrigger Event`);

        let
        _trigger  = $(e.currentTarget),
        _target   = _trigger.data('scroll-target'),
        _href     = _trigger.attr('href') || false;

        // Check if Trigger has a scroll-target set as string, but no element w/ id = scroll-target exists
        // => open the url set by href attribute and add scroll-target as hash
        if (typeof _target === 'string' && !$(_target).length && _href)
        {
            if (this._debug) console.log(`FX::_handleTrigger Event w/ link and scroll-target set`);

            let
            _url        = _href + _target,
            _linkTarget = _trigger.attr('target') || '_self';

            window.open(_url, _linkTarget);

            return;
        }

        this.scrollTo(_target);
    }

    hiliteTarget(target, mode = 'show')
    {
        switch(mode) {
            case 'show':
                $(target)
                    .find('.hoverable')
                    .addClass('hover');
                gsap.delayedCall(5, this.hiliteTarget.bind(this), [target, 'hide']);
            break;
            case 'hide':
                $(target)
                    .find('.hoverable')
                    .removeClass('hover');
            break;
        }
    }

    scrollTo(target, offset = APP.headerHeight)
    {
        let
        _target   = target,
        _top      = offset + 100;

        if (this._debug) console.log(`FX::scrollTo w/ target = ${_target} and offset = ${offset}`);

        if (this.smoother)
        {
            // Use 'ScrollSmoother Plugin' if activated (@see: https://greensock.com/docs/v3/Plugins/ScrollSmoother)
            if (this._debug) console.log(`FX::scrollTo w/ Scrollsmoother`);

            let _offset = 0;
            if (typeof _target === 'number')
            {
                _offset = _target;
            } else {
                _offset = this.smoother.offset(_target);

                this.hiliteTarget(_target);
            }
            _offset -= _top;

            gsap.to(this.smoother,
                {
                    // don't let it go beyond the maximum scrollable area
                    scrollTop: Math.min(ScrollTrigger.maxScroll(window), _offset),
                    duration: this.smoother.smooth()
                }
            );
        }
        else
        {
            // Use native scroll if 'ScrollSmoother Plugin' is not activated
            if (this._debug) console.log(`FX::scrollTo w/ native scroll`);
            if (typeof _target !== 'number' && typeof $(_target) === 'object')
            {
                _target = $(_target).position().top;
            }
            _target -= _top;

            $(window).scrollTop(_target);
        }
    }
}

export default FX;

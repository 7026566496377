/**!
 *  SLIDER-BASE
 *
 *  @name			      slider-base.js (js/ws/slick.js)
 *
 *  @package       JOOMLA BASE THEME
 *  @description	 Custom implementation of kenwheeler's  slick.js
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
*/

// import 'waypoints/src/waypoint.js';
import 'slick-carousel';
import ImageSliderModal from './image-slider-modal';
import Html5Player from '../video/html5';

class Slider
{
    constructor(slider, debug = false)
    {
        if (typeof slider !== 'object') { return false; }
        if (debug) console.log('Slider:constructor (js/ws/slick.js)');
        this._debug = debug;

        // keep the html setup of the slider from before the slider is initialized
        // in case a ModalSlider is needed
        this.slider_setup_html = slider.clone();
        this.slider = slider;
        this.videoslides = [];

        this.opt = {
            type: 'default',
						ratio: this.slider.find('img').first().data('ratio') || APP.heroRatio,
            // Slick options
            // @see https://kenwheeler.github.io/slick/ for possible options
            dots: true,
            lazyLoad: 'ondemand',
            arrows: !APP.useSmallScreenBehavior,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            fade: true,
            cssEase: 'ease-in-out',
            pauseOnHover: false,
            pauseOnFocus: false

        };
        $.extend(this.opt, this.slider.data());

				if (typeof this.slider.slick !== 'function')
				{
            if (this._debug) console.log(`ERROR: slick-carousel not imported`);

            return false;
				}

        this.__init();
    }

		__init()
		{
				if (this._debug) console.log(`Slider:__init (js/ws/slick.js)`);

        let _this = this;
        if (this.opt.enableModalView)
        {
            // Activate Modal View
            this._modalView = new ImageSliderModal(this.slider_setup_html, {
                title: this.opt.title,
            }, DEBUGS.sliderModal);
        }

				this.slider
						.on('init', function(event, slick, direction)
								{
										if (_this._debug) console.log(`Slick-Event: ${event.tye}`);

                    let
                    _currentSlide = slick.currentSlide,
                    _slides = slick.$slider.find('.slide');
                    _slides.each(function(i, el)
                        {
                            if ($(el).hasClass('js-videoslide'))
                            {
                                if (typeof _this.videoslides[i] !== 'object')
                                {
                                    _this.videoslides[i] = new Html5Player($(el), DEBUGS.html5Player);
                                    _this.videoslides[i].mute(); // To make sure videoslides can 'autoplay' videos need to be default muted
                                    _this.videoslides[i].on('play',  function() { slick.paused = true;  });
                                    _this.videoslides[i].on('pause', function() { slick.paused = false;  });
                                }
                            } else {
                                _this.videoslides[i] = false;
                            }
                        }
                    );
                    if (typeof _this.videoslides[_currentSlide] === 'object')
                    {
                        /*
                        * THE CUE 'OUT' LISTENER
                        *
                        * Cue 'out' is fired 0.5 seconds before the video ends (by ws/video/html5.js)
                        * and initiates 'slickNext' on the slider so the slides changes while the video is
                        * still playing which looks nicer then having the video end (and freeze) before
                        * slides change. Here this is only set for the first Slide. All other slides
                        * are handled on 'beforeChange' event.
                        */
                        _this.videoslides[_currentSlide].on('cue', function(obj)
                            {
                                if (_this._debug) console.log(`cue: ${obj.name} / slide: ${_currentSlide}`);
                                if (obj.name === 'out')
                                {
                                    slick.$slider.slick('slickNext');
                                }
                            }
                        );
                        _this.videoslides[_currentSlide].start();
                    }
								}
						)
            .on('setPosition', function(event, slick, direction)
                {
                    if (_this._debug) console.log(`Slick-Event: ${event.type}`);

                    let
                    _holder = slick.$slider.parent(),
                    __w, __h;

                    if (_this.opt.type === 'hero')
                    {
                        __h  = APP.heroHeight;
                        slick.$slider.css({ height: __h });
                        slick.$slider.find('.slide-container').css({ height: __h });
                        // if (APP.currentBP === 'xs' || APP.currentBP === 'sm')
                        // {
                        //     slick.$slider.css({ height: (__h * 1.8) });
                        //     slick.$slider.find('.slide-container').css({ height: (__h * 1.8) });
                        //     slick.$slider.find('.js-videoslide').css({ height: __h });
                        //     slick.$slider.find('.hero-slider-card').css({ height: (__h * .8) });
                        // } else {
                        //     slick.$slider.css({ height: __h });
                        //     slick.$slider.find('.slide-container').css({ height: __h });
                        //     slick.$slider.find('.js-videoslide').css({ height: 'inherit' });
                        //     slick.$slider.find('.hero-slider-card').css({ height: 'auto' });
                        // }
                    }
                    else
                    {
                        __w = slick.slideWidth;
                        __h = (__w * APP.heroRatio);
                    }
                }
            )
						.on('lazyLoaded', function(event, slick, direction)
								{
										if (_this._debug) console.log(`Slick-Event: ${event.type}`);
										APP.swapImagesByBreakpoint(slick.$slider.find('img.figure-img'));
								}
						)
            .on('beforeChange', function(event, slick, currentSlide, nextSlide)
								{
                    if (_this._debug) console.log(`Slick-Event: ${event.type}`);

                    let _next = slick.$slider.find('.slide-' + nextSlide);

                    if (_next.hasClass('js-videoslide'))
                    {
                        /*
                        * THE CUE 'OUT' LISTENER
                        * @see: line 100 ff.
                        */
                        _this.videoslides[nextSlide].on('cue', function(obj)
                            {
                                if (_this._debug) console.log(`cue: ${obj.name} / slide: ${nextSlide}`);

                                if (obj.name === 'out')
                                {
                                    slick.$slider.slick('slickNext');
                                }
                            }
                        );
                        _this.videoslides[nextSlide].start();
                    }
                }
            )
            .on('afterChange', function(event, slick, currentSlide)
								{
										if (_this._debug) console.log(`Slick-Event: ${event.type}`);

                    let
                    _previousSlide = (currentSlide > 0) ? (currentSlide - 1) : slick.slideCount,
                    _current  = slick.$slider.find('.slide-' + currentSlide),
                    _previous = slick.$slider.find('.slide-' + _previousSlide);

                    if (_previous.hasClass('js-videoslide'))
                    {
                        _this.videoslides[_previousSlide].stop();
                    }
                    if (!_current.hasClass('js-videoslide'))
                    {
                        // If the current-slide is not a videoslide, the transition to next-slide must be
                        // handeled by the slider's native behavior.
                        slick.paused = false;
                    }
								}
						)
				;

        let options = {};

				switch(this.opt.type)
				{
            // DEFAULT SLIDER
						case 'default':
                if (this._debug) console.log(`Slider:__init type = default`);
                if (this._debug) console.log(this.opt);
								this.slider.slick(this.opt);
						break;

						//	HERO-SLIDER
						// (mod_djimageslider.hero)
						case 'hero':
                options = this.opt;
                options.speed = 500;
                options.arrows = true;
                if (this._debug) console.log(`Slider:__init type = hero`);
                if (this._debug) console.log(options);
                // initialize the slider (slick.js)
								this.slider.slick(options);

                let
                _this = this,

                // This handles a 'click' on custom dot's which are placed inside the slides (as in brandit.de).
                tabs = this.slider.find('.dot');
                tabs.on('click', function(e)
                    {
                        e.preventDefault();
                        let _slide = $(e.target).data('goto');
                        _this.slider.slick('slickGoTo', _slide);

                        // prevent detail link when clicking tabs
                        return false;
                    }
                );
						break;

						//	RESPONSIVE-SLIDER
						// (mod_djimageslider.responsive)
						case 'responsive':
                options = {
                    // Customize options for 'responsive type' here:
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: APP.BP['lg'],
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: APP.BP['sm'],
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                };
                $.extend(options, this.opt);
                if (this._debug) console.log(`Slider:__init type = responsive`);
                if (this._debug) console.log(options);
                // initialize the slider (slick.js)
								this.slider.slick(options);
						break;
				}
		}
}

export default Slider;

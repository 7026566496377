/**!
 * APP
 *
 *  @name				   app.js (js/app.js)
 *
 *  @package       JOOMLA BASE THEME
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 */

// require('../scss/app.scss');

/* DEBUG and ENV Setup */
let ENV = 'dev';
if (window.ENV)
{
    ENV = window.ENV;
}
let DEBUG = false;
if (window.DEBUG)
{
    DEBUG = !!parseInt(window.DEBUG);
}
let FB_ID = false;
if (window.FB_ID)
{
    FB_ID = window.FB_ID;
}
let JOBNR = 'SPONTANEOUS';
if (window.JOBNR)
{
    JOBNR     = window.JOBNR;
}
global.DEBUG  = DEBUG;
global.ENV    = ENV;
global.FB_ID  = FB_ID;
global.JOBNR  = JOBNR;



/* Activate logging output for specific js components in template settings */
global.DEBUGS = {
             'app': false,
            'base': false,
     'html5Player': false,
              'FX': false,
      'sliderBase': false,
     'sliderModal': false,
          'wsmaps': false,
        'calendar': false,
           'share': false
};
let DBS = [];
if (window.DBS)
{
    DBS = JSON.parse(window.DBS);
    for (var i = 0; i < DBS.length; i++)
    {
        DEBUGS[DBS[i]] = true;
    }
}

import $ from 'jquery';
global.$ = $;

import { gsap } from 'gsap';
global.gsap = gsap;

import * as bootstrap from 'bootstrap';
global.bootstrap = bootstrap;

import Bowser from 'bowser';
import "chosen-js";

// Masonry
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
jQueryBridget( 'masonry', Masonry, $ );
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

import Utils from './ws/utils';
global.Utils = Utils;

import GmapBase from './ws/gmap/gmap-base';
global.GmapBase = GmapBase;

import Slider from './ws/slider/slider-base';
import Calendar from './ws/calendar';
import ImageSliderModal from './ws/slider/image-slider-modal';
import Html5Player from './ws/video/html5';
import YouTubePlayer from './ws/video/youtube';
import VimeoPlayer from './ws/video/vimeo';
import SharingIsCaring from './ws/share/sharing-is-caring';

const
DEVICE = Bowser.parse(window.navigator.userAgent),
OFFSET_TOP_DEFAULT = 250,
SCROLL_DELAY_DEFAULT = 1,
RESIZE_HEIGHT_THRESHOLD = 70,
PRESERVE_SIDEBAR_STATE = false,
ANIMATION_TYPE = 2,

// Breakpoints
BP = {
	 xs: 0,
	 sm: 576,
	 md: 768,
	 lg: 992,
	 xl: 1200,
  xxl: 1600
},

// Must match @grid-float-breakpoint defined in "_variables.scss"
GRID_FLOAT_BREAKPOINT = BP.lg,

HERO_RATIO_DEFAULT = 0.5625, // 16:9 (0.5625)

HERO_MAX_HEIGTHS = {
    xs: 460,
    sm: 320,
    md: 320,
    lg: 560,
    xl: 560,
   xxl: 560
},

HERO_RATIOS = {
    xs: 0.75,
    sm: 0.5625,
    md: 0.5625,
    lg: 0.5625,
    xl: 0.5625,
   xxl: 0.5625
},

HEADER_HEIGHTS = {
  xs: 90,
  sm: 90,
  md: 100,
  lg: 110,
  xl: 115,
 xxl: 120
};

global.OFFSET_TOP_DEFAULT = OFFSET_TOP_DEFAULT;
global.SCROLL_DELAY_DEFAULT = SCROLL_DELAY_DEFAULT;
global.HERO_RATIO_DEFAULT = HERO_RATIO_DEFAULT;
global.HERO_MAX_HEIGTHS = HERO_MAX_HEIGTHS;
global.HERO_RATIOS = HERO_RATIOS;
global.HEADER_HEIGHTS = HEADER_HEIGHTS;
global.GRID_FLOAT_BREAKPOINT = GRID_FLOAT_BREAKPOINT;
global.BP = BP;
global.RESIZE_HEIGHT_THRESHOLD = RESIZE_HEIGHT_THRESHOLD;
global.PRESERVE_SIDEBAR_STATE = PRESERVE_SIDEBAR_STATE;
global.ANIMATION_TYPE = ANIMATION_TYPE;

let THEME = 'light';

if (window.THEME)
{
    THEME = window.THEME;
}
global.THEME = THEME;

import FX from './ws/fx';
import Base from './ws/base';

if (DEBUGS.app)
{
    console.log(`
        ================================================ \n
        DEVICE DETECTION \n
        ================================================ \n
        Browser:  ${DEVICE.browser.name} (${DEVICE.browser.version}) \n
        OS:       ${DEVICE.os.name} (${DEVICE.os.version}) \n
        Engine:   ${DEVICE.engine.name} (${DEVICE.engine.version}) \n
        Platform: ${DEVICE.platform.type} (${DEVICE.platform.vendor}) \n
        ================================================ \n
    `);

    console.log(`
        ================================================ \n
        GLOBALS \n
        ================================================ \n
        ENV:                      ${ENV} \n
        DEBUG:                    ${global.DEBUG} \n
        GRID_FLOAT_BREAKPOINT:    ${global.GRID_FLOAT_BREAKPOINT} \n
        HERO_RATIO_DEFAULT:       ${global.HERO_RATIO_DEFAULT} \n
        OFFSET_TOP_DEFAULT:       ${global.OFFSET_TOP_DEFAULT} \n
        SCROLL_DELAY_DEFAULT:     ${global.SCROLL_DELAY_DEFAULT} \n
        RESIZE_HEIGHT_THRESHOLD:  ${global.RESIZE_HEIGHT_THRESHOLD} \n
        PRESERVE_SIDEBAR_STATE:   ${global.PRESERVE_SIDEBAR_STATE} \n
        ANIMATION_TYPE:           ${global.ANIMATION_TYPE} \n
        THEME:                    ${global.THEME} \n
        ================================================ \n
    `);
}

$(document).ready(function()
{   'use strict';

    global.APP  = new Base(DEBUGS.base);
    global.FX   = new FX(DEBUGS.FX);

    /* Find GET params in current browser location */
    let
    _query = Utils.getGETParams(),
    _GET   = {};

    global.GET = {};

    if (_query)
    {
        let
        get = _query.split('&'),
        i = 0;
        for(i; i < get.length; i++)
        {
            GET[get[i].split('=')[0]] = get[i].split('=')[1];
        }

        // Strip GET Params from browser location
        Utils.refineURL();

        if (DEBUGS.app) console.log('GET Params Encountered:');
        if (DEBUGS.app) console.log(GET);
    }

    let
    slider = $('.slick'),
    SLIDERS = [];
    if (slider.length)
    {
        slider.each(function(i,el)
            {
                SLIDERS[i] = new Slider($(el), DEBUGS.sliderBase);
            }
        );
    }

    // SIDEBAR
    if ($(APP.SIDEBAR).length)
    {
        let _sidebar = bootstrap.Offcanvas.getOrCreateInstance(APP.SIDEBAR);
        APP.SIDEBAR.addEventListener('show.bs.offcanvas',
            event => {
                if (DEBUGS.app) console.log('sidebar-show');
                APP.PAGE
                    .addClass('sidebar-show')
                    .addClass('sidebar-transition')
                    .removeClass('sidebar-hidden');
            }
        );
        APP.SIDEBAR.addEventListener('shown.bs.offcanvas',
            event => {
                if (DEBUGS.app) console.log('sidebar-shown');
                APP.PAGE
                    .removeClass('sidebar-transition')
                    .removeClass('sidebar-show')
                    .addClass('sidebar-shown');
                APP.isSidebarExpanded = true;
                APP.SB_BURGER.removeClass('collapsed');
            }
        );
        APP.SIDEBAR.addEventListener('hide.bs.offcanvas',
            event => {
                if (DEBUGS.app) console.log('sidebar-hide');
                APP.PAGE
                    .addClass('sidebar-hide')
                    .addClass('sidebar-transition')
                    .removeClass('sidebar-shown');
            }
        );
        APP.SIDEBAR.addEventListener('hidden.bs.offcanvas',
            event => {
                if (DEBUGS.app) console.log('sidebar-hidden');
                APP.PAGE
                    .removeClass('sidebar-transition')
                    .removeClass('sidebar-hide')
                    .addClass('sidebar-hidden');
                APP.isSidebarExpanded = false;
                APP.SB_BURGER.addClass('collapsed');
            }
        );
        if (PRESERVE_SIDEBAR_STATE && APP.isSidebarExpanded) _sidebar.show();
    }

    // NAVBAR
    if ($(APP.NAVBAR).length)
    {
        APP.NAVBAR.addEventListener('show.bs.collapse',
            event => {
                if (event.target !== APP.NAVBAR) return; // prevent bubbling
                if (DEBUGS.app) console.log('navbar-show');
                APP.PAGE
                    .addClass('navbar-show')
                    .addClass('navbar-transition')
                    .removeClass('navbar-hidden');
                APP.backdrop('navi');
            }
        );
        APP.NAVBAR.addEventListener('shown.bs.collapse',
            event => {
                if (event.target !== APP.NAVBAR) return; // prevent bubbling
                if (DEBUGS.app) console.log('navbar-shown');
                APP.PAGE
                    .removeClass('navbar-transition')
                    .removeClass('navbar-show')
                    .addClass('navbar-shown');
                APP.NB_BURGER.removeClass('collapsed');
                APP.backdrop('show');
            }
        );
        APP.NAVBAR.addEventListener('hide.bs.collapse',
            event => {
                if (event.target !== APP.NAVBAR) return; // prevent bubbling
                if (DEBUGS.app) console.log('navbar-hide');
                APP.PAGE
                    .addClass('navbar-hide')
                    .addClass('navbar-transition')
                    .removeClass('navbar-shown');
                APP.backdrop('hide');
            }
        );
        APP.NAVBAR.addEventListener('hidden.bs.collapse',
            event => {
                if (event.target !== APP.NAVBAR) return; // prevent bubbling
                if (DEBUGS.app) console.log('navbar-hidden');
                APP.PAGE
                    .removeClass('navbar-transition')
                    .removeClass('navbar-hide')
                    .addClass('navbar-hidden');
                APP.NB_BURGER.addClass('collapsed');
            }
        );
    }

    let
    $introItemsSlider = $('#IntroItemsSlider'),
    $leadItemsSlider  = $('#LeadItemsSlider'),
    $itemImageList    = $('#itemImageList');
    if ($introItemsSlider.length)
    {
        $introItemsSlider = new ImageSliderModal($introItemsSlider,
            {
                size: 'fullscreen',
                showCloseBtn: true
            },
            DEBUGS.sliderModal
        );
    }
    if ($leadItemsSlider.length)
    {
        $leadItemsSlider = new ImageSliderModal($leadItemsSlider,
            {
                size: 'fullscreen',
                showCloseBtn: true
            },
            DEBUGS.sliderModal
        );
    }
    if ($itemImageList.length)
    {
        $itemImageList = new ImageSliderModal($itemImageList,
            {
                size: 'fullscreen',
                showCloseBtn: true
            },
            DEBUGS.sliderModal
        );
    }

    /*
    *   MASONRY-BLOG
    */
    let $grid = $('#IntroItemsMasonry, #itemImageList.masonry');
    if ($grid.length)
    {
        $grid.imagesLoaded(() => {

            $grid.masonry(
                    {
                        itemSelector: '.grid-item',
                        columnWidth: '.grid-item',
                        percentPosition: true,
                        horizontalOrder: true,      // Maintains horizontal order of items (ltr and ttb)
                        originTop: true,            // Set to false for bottom-up layouts
                    }
                )
            ;
            $grid.masonry('layout');
        });

        APP.on('scrollStart', function()
            {
                $grid.masonry('layout');
            }
        );
        APP.on('scrollEnd', function()
            {
                $grid.masonry('layout');
            }
        );
    }

    // Event-Dates-Calendar
    var
    calendar = $('#navbar-calendar, #sidebar-calendar'),
    CALENDARS = [];
    if (calendar.length)
    {
        calendar.each(function(i,el)
            {
                CALENDARS[i] = new Calendar($(el), DEBUGS.calendar);
            }
        );
    }
    // Upcoming Events => scroll to date.
    if (GET.day)
    {
        gsap.delayedCall(2, scrollTo.bind(this), ['#event-' + GET.day]);

        function scrollTo(target)
        {
            if (typeof FX === 'object')
            {
                FX.scrollTo(target);

            }
        }
    }

    /*
    *   HTML5-VIDEO-PLAYER
    */
    let
    videos = $('.js-video'),
    VIDEOS = [];
    if (videos.length)
    {
        videos.each(function(i,el)
            {
                VIDEOS[i] = new Html5Player($(el), DEBUGS.html5Player);
                VIDEOS[i].on('play', pauseOthers.bind(this));
            }
        );

        function pauseOthers(player)
        {
            for(var i = 0; i < VIDEOS.length; i++)
            {
                if (VIDEOS[i] !== player)
                {
                    VIDEOS[i].stop();
                }
            }
        }
    }

    let
    youtube = $('.js-youtube'),
    YT = [];
    if (youtube.length)
    {
        youtube.each(function(i,el)
                {
                    YT[i] = new YouTubePlayer($(el));
                }
            )
        ;
    }

    let
    vimeo = $('.js-vimeo'),
    VIM = [];
    if (vimeo.length)
    {
        vimeo.each(function(i,el)
                {
                    VIM[i] = new VimeoPlayer($(el));
                }
            )
        ;
    }

    if (FB_ID)
    {
        global.SIC = new SharingIsCaring(FB_ID, DEBUGS.share);
    }

    let _info_block = $('.info-block');
    if (_info_block.length && typeof SIC === 'object')
    {
        SIC.wireChildren(_info_block);
    }

    let
    map,
    api_key,
    gmap = $('#WSMap');

    if (gmap.length)
    {
        map = new GmapBase(gmap, DEBUGS.wsmaps);
    }
});

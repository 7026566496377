/**!
 *  CALENDAR
 *
 * 	@name			   	calendar.js
 * 	@desc			   	module w/ event-calendar features (customized DJ-Events-Calendar Module).
 *
 * 	@client			 	WKJ
 * 	@author 			Ansgar Hiller <ansgar@weigelstein.de>
 * 	@since			  01-2021
*/

import $ from 'jquery';
import { gsap } from 'gsap';

import Base from './base';

class Calendar
{
		get isDropdown() { return (this.dropdown.length > 0); }
		get url() { return this._url; }
		get module() { return this._module; }
		get layout() { return this._layout; }
		get days() { return this.wrapper.find('.active-day'); }
    get data() { return this._data; }

    get id() { return this._id; }
    get itemid() { return this._itemid; }
		get nextMonthBtn() { return this._element.find('.next-month'); }
		get prevMonthBtn() { return this._element.find('.prev-month'); }
		get currentMonth() { return this._month; }
    get currentYear() { return this._year; }

		get previewContainer() { return this._element.find('.calendar-day-previews'); }
    get dropdown() { return this._dropdown; }

		get wrapper() { return this._wrapper; }
		set wrapper(el) { this._wrapper = el; }

		get xhr() { return this._xhr; }
		set xhr(xhr) { this._xhr = xhr; }

		constructor(element, debug = false)
		{
        this._debug = debug;

				this._element = element;
        this._data = this._element.data();
				this._wrapper = this._element.find('.calendar-wrap');
				this._id = this._element.attr('id');
				this._xhr = null;
				this._url = this._data.baseUrl;
				this._module = this._data.module;
				this._layout = this._data.layout;
        this._month = this._data.month;
        this._year = this._data.year;
        this._id = this._data.id;
        this._itemid = this._data.itemid;
				this._position = this._data.position;

				if (this._debug) console.log(`Calendar::constructor w/ element._itemid = #${this._itemid} (js/ws/calendar.js)`);

				this._dropdown = this._element.closest('[data-toggle="dropdown"]');

				this.wire();

				var _data = this.createDataString(0);
				this._loadMonth(_data);
		}

		wire()
		{
				if (this._debug) console.log(`Calendar::wire`);

				var _this = this;

				this.nextMonthBtn.on(
						{
							'click': this._next.bind(this)
						}
				)

				this.prevMonthBtn.on(
						{
							'click': this._prev.bind(this)
						}
				)

				this.days.each(function(i,el)
						{
								$(el).on(
										{
												     'click': _this._onDayClicked.bind(_this),
												'mouseenter': _this._onDayHover.bind(_this),
												'mouseleave': _this._onDayHover.bind(_this)
										}
								)
						}
				)

				if (GET.day && typeof FX === 'object')
				{
            gsap.delayedCall(1, FX.scrollTo.bind(this), ['#event-' + GET.day]);
						GET.day = false;
				};
		}

		createDataString(direction)
		{
        if (parseInt(this.currentMonth) + direction === 13) {
            this._month = 1;
            this._year++;
        } else
        if (parseInt(this.currentMonth) + direction === 0) {
            this._month = 12;
            this._year--;
        } else {
            this._month = parseInt(this.currentMonth) + direction;
        }
				return 'option=com_eventbooking'
								+ '&view=calendar'
								+ '&format=raw'
								+ '&month=' + this.currentMonth
								+ '&year=' + this.currentYear
								+ '&id=' + this.id
                + '&Itemid=' + this.itemid
								+ '&layout=mini';
		}

		_next(e)
		{
				if (this._debug) console.log(`Calendar::_next (Event)`);
				e.preventDefault();

				var
				_btn = $(e.delegateTarget),
				_data = this.createDataString(1);

				this._loadMonth(_data);

				return false;
		}

		_prev(e)
		{
				if (this._debug) console.log(`Calendar::_prev (Event)`);
				e.preventDefault();

				var
				_btn = $(e.delegateTarget),
				_data = this.createDataString(-1);

				this._loadMonth(_data);

				return false;
		}

		_onDayClicked(e)
		{
				if (this._debug) console.log(`Calendar::_onDayClicked (Event) w/ e.type = ${e.type}`);
				e.preventDefault();

				var
				_btn = $(e.delegateTarget).find('.ball'),
				_link = _btn.attr('href');

				if (this._position === 'mini-cal')
				{
						window.open(_link,'_top');

						return false;
				}

				_link = '#event-' + _link.split('=')[1];

				if (typeof FX === 'object' && $(_link).length)
				{
						FX.scrollTo(_link, 130);
				};

				return false;
		}

		_onDayHover(e)
		{
				if (this._debug) console.log(`Calendar::_onDayHover (Event) w/ e.type = ${e.type}`);
				e.preventDefault();

				var
				_this = this,
				_btn = $(e.delegateTarget).find('.ball'),
				_data = _btn.data(),
				_preview = '';
				_preview += '<div class="calendar-event px-3">';
        _data.events.forEach((item, i) => {
            _preview += (null !== item.time) ? '<span class="event-time me-2">' + item.time + ' Uhr </span>' : '';
            _preview += '<span class="event-title ' + item.class + '">' + item.title + '</span>';
            _preview += '<br>';
        });
				_preview += '</div>';

				switch(e.type) {
						case 'mouseenter':
								_this.previewContainer.append($(_preview));
						break;
						case 'mouseleave':
								_this.previewContainer.empty();
						break;
				}

				return false;
		}

		_loadMonth(dataString)
		{
				if (this._debug) console.log(`Calendar::_loadMonth w/ dataString = ${dataString}`);

				var _this = this;

				if (this.xhr && this.xhr.readyState != 4) {
						return;
				}

				this._element.addClass('loading');

				this.xhr = $.ajax(
						{
								url: this.url,
								method: 'post',
								data: dataString
						}
				).done(function(html)
						{
								if (html !== '') _this._updateMonth(html);
						}
				).always(function()
						{
								_this._element.removeClass('loading');
						}
				);
		}

		_updateMonth(html)
		{
				// console.log($(html).find('.calendar-wrap'));
				this._wrapper.replaceWith($(html).find('.calendar-wrap'));
				this.wrapper = this._element.find('.calendar-wrap').first();

				this.wire();
		}
}

export default Calendar;

/**!
 *  HTML5-VIDEO
 *
 *  @name			   html5.js
 *  @desc			   html5 video player.
 *
 *  @client			 WEIGELSTEIN
 *  @author 		 Ansgar Hiller <ansgar@weigelstein.de>
 *  @since			 03-2022
*/

const _HIDE_PLAYBUTTON_DELAY_DEFAULT = 1; // Delay until Playbutton is hidden after the video started playing (in seconds)

class Html5Player
{
		get DEBUG() 				  { return this._debug; }

		get id() 						  { return this._id; }
		set id(p) 					  { this._id = p; }

		get outCueOffset()
    {
        return this._outCueOffset;
    }
    set outCueOffset(t)
    {
        this._outCueOffset = t;
        this._cues = [];

        if (this.duration)
        {
            this._cues[0]  = {
                 name: 'in',
                    t: t,
                fired: false
            };

            this._cues[1]  = {
                 name: 'out',
                    t: (this.duration - t),
                fired: false
            };
        }
    }

    get state()   			  { return this._state; }
    set state(p)   			  { this._state = p; }

		get video() 				  { return this._video; }
		get canplay() 			  { return this._canplay; }
		get options() 			  { return this._opt; }
    get autoplay() 			  { return this._opt.autoplay; }
    get btnTogglePlay()   { return this._btnTogglePlay; }
    get btnToggleMute()   { return this._btnToggleMute; }
    get poster() 				  { return this._poster; }
    get posterURL() 			{ return this._posterURL; }
    get duration() 				{ return this._video.duration; }
    get cues()            { return this._cues; }

		constructor(element, debug = false)
		{
        // ---------------
        if (debug) console.log(`Html5Player::constructor (js/ws/html5.js)`);
        // ---------------
				this._debug 				  = debug;
				this._element 			  = element;
				this._id 						  = this._element.attr('id');
        this._video           = this._element[0].querySelector("video");
				this._canplay 			  = !!document.createElement('video').canPlayType;
        this._timeoutHideId   = null;
        this._outCueOffset    = 0.7; // default (seconds)
        this._state 				  = 'paused';
        this._cues            = [];

        // Options
				this._opt = {
            nativeControls: 0,
            poster: null,
            src: null,
            hidePlaybuttonDelay: _HIDE_PLAYBUTTON_DELAY_DEFAULT,
            autoplay: false,
            togglePlayBtn: null,
            toggleMuteBtn: null,
            controlsContainer: null
        };
				$.extend(this._opt, this._element.data());

        this._posterURL = (this._opt.poster !== null) ? this._opt.poster : $(this._video).attr('poster');
        this._poster = (this._element.find('.poster-layer').length) ?
            this._element.find('.poster-layer') :
						$('<div/>')
								.addClass('poster-layer fade')
								.appendTo(this._element);

        // Toggle-Play Button
        this._btnTogglePlay = (null !== this._opt.togglePlayBtn && $(this._opt.togglePlayBtn).length) ?
						$(this._opt.togglePlayBtn) :
						$('<button/>')
								.addClass('btn btn-link toggle-play fade')
								.attr({
                    'name': 'togglePlay',
                    'type': 'button'
                })
								.appendTo(this._element);
        let
        _icnPlay  = $('<span />').addClass('icon icon-controller-play icon-play').appendTo(this._btnTogglePlay),
        _icnPause = $('<span />').addClass('icon icon-controller-paus icon-paus').appendTo(this._btnTogglePlay);

        // Toggle-Mute Button
        this._btnToggleMute = (null !== this._opt.toggleMuteBtn && $(this._opt.toggleMuteBtn).length) ?
						$(this._opt.toggleMuteBtn) :
						$('<button/>')
								.addClass('btn btn-link toggle-mute fade')
								.attr({
                    'name': 'toggleMute',
                    'type': 'button'
                });
        let
        _icnUnmute  = $('<span />').addClass('icon icon-sound icon-unmute').appendTo(this._btnToggleMute),
        _icnMute = $('<span />').addClass('icon icon-sound-mute icon-mute').appendTo(this._btnToggleMute);

        let _controlscontainer = (null !== this._opt.controlsContainer && $(this._opt.controlsContainer).length) ?
            $(this._opt.controlsContainer) : false;

        if (_controlscontainer) {
            this._btnToggleMute.appendTo(_controlscontainer);
            this._btnTogglePlay.appendTo(_controlscontainer);
            _controlscontainer.on(
    						{
    								'mouseenter mouseleave mousemove click': this.__hover.bind(this),
    						}
    				);
        } else {
            this._btnToggleMute.appendTo(this._element);
            this._btnTogglePlay.appendTo(this._element);
        }

				// Events
				$(this.video).on(
						{
								'mouseenter mouseleave mousemove click': this.__hover.bind(this),
						}
				);

        this.btnTogglePlay.on(
            {
                'click': this.__togglePlay.bind(this),
                'mouseenter mouseleave mousemove': this.__hover.bind(this),
            }
        );

        this.btnToggleMute.on(
            {
                'click': this.__toggleMute.bind(this),
                'mouseenter mouseleave mousemove': this.__hover.bind(this),
            }
        );

        if (this.autoplay)
        {
            this.state = 'playing';
        }

        this.video.addEventListener('hover',          this.__hover.bind(this));
        this.video.addEventListener('canplaythrough', this.__init.bind(this));
        this.video.addEventListener('play',           this.__status.bind(this));
        this.video.addEventListener('pause',          this.__status.bind(this));
        this.video.addEventListener('ended',          this.__status.bind(this));
        this.video.addEventListener('timeupdate',     this.__update.bind(this));

        this._onPlayerReadyEvents = $.Callbacks();
        this._onPlayerPlayEvents  = $.Callbacks();
        this._onPlayerPauseEvents = $.Callbacks();
        this._onPlayerEndedEvents = $.Callbacks();
        this._onPlayerCueEvents   = $.Callbacks();

        this.video.load();
		}

    on(event, fn)
		{
				if (typeof fn === 'function')
				{
						switch(event) {
                case 'ready':
                    this._onPlayerReadyEvents.add(fn);
                    break;
								case 'play':
                    this._onPlayerPlayEvents.add(fn);
                    break;
                case 'pause':
                    this._onPlayerPauseEvents.add(fn);
                    break;
                case 'ended':
                    this._onPlayerEndedEvents.add(fn);
                    break;
                case 'cue':
                    this._onPlayerCueEvents.add(fn);
                    break;
            }
        }
    }

    off(event, fn)
		{
				if (typeof fn === 'function')
				{
						switch(event) {
                case 'ready':
                    this._onPlayerReadyEvents.remove(fn);
                    break;
								case 'play':
                    this._onPlayerPlayEvents.remove(fn);
                    break;
                case 'pause':
                    this._onPlayerPauseEvents.remove(fn);
                    break;
                case 'ended':
                    this._onPlayerEndedEvents.remove(fn);
                    break;
                case 'cue':
                    this._onPlayerCueEvents.remove(fn);
                    break;
            }
        }
    }

		__init()
		{
				// ---------------
				if (this._debug) console.log(`Html5Player::__init`);
				// ---------------

				this.video.controls = this.options.nativeControls;
        if (this.posterURL) this.poster.css({ 'background-image': 'url(' + this.posterURL + ')' });

        if (this.state !== 'playing')
        {
            this.btnTogglePlay.addClass('show');
            this.poster.addClass('show');
        }

        this.btnToggleMute.addClass('show');

        if (this.video.muted) this.btnToggleMute.addClass('muted');

        // add 'in'/'out' to cues
        this.outCueOffset = this._outCueOffset;
        this.video.removeEventListener('canplaythrough', this.__init.bind(this));
		}

		__hover(e)
		{
				// ---------------
				if (this._debug) console.log(`Html5Player::__hover w/ type = ${e.type}`);
				// ---------------

        let _this = this;

        if (this.state !== 'playing') return;
        if (this._timeoutHideId) clearTimeout(this._timeoutHideId);
				switch(e.type) {
						case 'mouseenter':
            case 'mousemove':
								this.btnTogglePlay.addClass('show');
                this.btnToggleMute.addClass('show');
                this.__hidePlayButton();
						break;
						case 'mouseleave':
								this.btnTogglePlay.removeClass('show');
                if (!this.video.muted) this.btnToggleMute.removeClass('show');
						break;
				}
		}

    __status(e)
    {
				// ---------------
        if (this._debug) console.log(`Html5Player::__status w/ type = ${e.type}`);
				// ---------------

        // reset out cue
        this.outCueOffset = this._outCueOffset;

        switch(e.type) {
						case 'play':
                this.state = 'playing';
								this.btnTogglePlay.addClass('playing');
                this.poster.removeClass('show');
								this.__hidePlayButton();
                if (!this.video.muted) this.btnToggleMute.removeClass('show');
                this._onPlayerPlayEvents.fire(this);
								break;
						case 'pause':
                this.state = 'paused';
                this.btnTogglePlay.removeClass('playing');
                if (!this.autoplay) this.btnTogglePlay.addClass('show');
                this.poster.addClass('show');
                if (this._timeoutHideId) clearTimeout(this._timeoutHideId);
                this._onPlayerPauseEvents.fire(this);
                break;
            case 'ended':
                this.state = 'ended';
								this.btnTogglePlay.removeClass('playing');
                this.btnToggleMute.addClass('show');
                if (!this.autoplay) this.btnTogglePlay.addClass('show');
                if (!this.autoplay) this.poster.addClass('show');
                if (this._timeoutHideId) clearTimeout(this._timeoutHideId);
                this._onPlayerEndedEvents.fire(this);
								break;
				}
    }

    __update(e)
    {
        // if (this._debug) console.log(`Html5Player::__update ${this.video.currentTime} / ${this.video.duration}`);
        this.__checkCues(this.video.currentTime);
    }

    __checkCues(t)
    {
        for (let obj of this.cues)
        {
            if (!obj.fired && t > obj.t)
            {
                obj.fired = true;
                this._onPlayerCueEvents.fire(obj);
                // ---------------
                if (this._debug) console.log(`Html5Player::__checkCues => onCueEvent fired w/ name: ${obj.name}`);
        				// ---------------
            }
        }
    }

		__togglePlay(e)
		{
				// ---------------
        if (this._debug) console.log(`Html5Player::__togglePlay ${(this.video.paused || this.video.ended)? 'play':'pause'}`);
				// ---------------

			  if (this.video.paused || this.video.ended)
				{
            if (this._timeoutHideId) clearTimeout(this._timeoutHideId);
			    	this.play();
			  } else {
			    	this.pause();
			  }
		}

    __toggleMute(e)
		{
				// ---------------
        if (this._debug) console.log(`Html5Player::__toggleMute ${this.video.muted}`);
				// ---------------

			  if (this.video.muted)
				{
            this.btnToggleMute.removeClass('muted');
			    	this.unmute();
			  } else {
            this.btnToggleMute.addClass('muted');
			    	this.mute();
			  }
		}

		__hidePlayButton()
		{
				let _this = this;

				this._timeoutHideId = setTimeout(
						function() {
								_this.btnTogglePlay.removeClass('show');
                if (!_this.video.muted) _this.btnToggleMute.removeClass('show');
						},
						_this.options.hidePlaybuttonDelay * 1000
				);
		}

    play()
    {
        this.btnTogglePlay.removeClass('show');
        this.btnToggleMute.removeClass('show');
        this.video.play();
    }

    pause()
    {
        this.video.pause();
    }

    start()
    {
        this.video.currentTime = 0;
        if (this.autoplay) this.btnTogglePlay.removeClass('show');
        this.video.play();
    }

    stop()
    {
        this.video.currentTime = 0;
        this.video.pause();
    }

    mute()
    {
        this.video.muted = true;
    }

    unmute()
    {
        this.video.muted = false;
    }
}

export default Html5Player;
